import React, {Component} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {connect} from 'react-redux';

import {deleteSenderId} from '../../actions/adminPanel';
import Select from '../../components/Select';

class DeleteSenderId extends Component {
  constructor() {
    super();
    this.state = {
      selected: '',
      loading: false
    };
    this.deleteSender = false;
  }
  componentWillReceiveProps(nextProps) {
    if (this.deleteSender && nextProps.deleteRes) {
      this.deleteSender = false;
      if (nextProps.deleteRes.success) {
        if (nextProps.deleteRes.data && nextProps.deleteRes.data.ok) {
          alert('Header Deleted Successfully');
          this.props.closeDetails();
        } else {
          this.setState({loading: false});
          alert(nextProps.deleteRes.data ? nextProps.deleteRes.data.message : 'Something went wrong, Please try again!');
        }
      } else {
        this.setState({loading: false});
        alert('Something went wrong, Please try again!');
      }
    }
  }
  handleChange = (e, index, selected) => this.setState({selected});
  deleteSenderId = () => {
    const {selected} = this.state;
    const {deleteSenderId, data} = this.props;
    if (selected) {
      if (selected === data.senderId) {
        alert('Default Header can not be deleted, please change the default header.');
      } else {
        this.deleteSender = true;
        deleteSenderId(selected, data.instituteId);
        this.setState({loading: true});
      }
    } else {
      alert('Please select Header');
    }
  }
  render() {
    const {selected, loading} = this.state;
    const {closeDeleteSenderId, data} = this.props;
    const isMobile = window.isMobile();
    return (
      <Dialog
        open={ true }
        onClose={ closeDeleteSenderId }
      >
        <DialogTitle>Delete Header</DialogTitle>
        <DialogContent style={{paddingTop: 16}}>
          <Select
            label='Select Header'
            value={ selected }
            fullWidth={ isMobile }
            onChange={ this.handleChange }
            options={data.senderIds}
            required
            sx={{minWidth: 200}}
          />
        </DialogContent>
        <DialogActions>
          {loading ? <CircularProgress /> : null}
          <Button onClick={closeDeleteSenderId}>Cancel</Button>
          <Button onClick={this.deleteSenderId} variant='contained' color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    deleteRes: state.adminPanel.deleteSenderIdResponse
  };
}

export default connect(mapStateToProps, {deleteSenderId})(DeleteSenderId);
