import React, {Component} from 'react';
import {ContentCopy} from '@mui/icons-material';
import {CircularProgress, IconButton, Tooltip} from '@mui/material';

import Api from '../../api';
import Table from '../../components/Table';
import {SMSAPI_URL_PATH} from '../../lib/constant';
import {getCookie} from '../../lib/utils';

export default class DeveloperPopover extends Component {
  state = {
    keys: {},
    loading: true
  }
  componentDidMount() {
    this.getAPIKey();
  }

  getAPIKey = () => {
    Api.get(`${ SMSAPI_URL_PATH }/api/institute/apikey`, (`bearer ${ getCookie('userToken') }`))
      .then(response => {
        const keys = {};
        if (response && response.data && response.data.data) {
          const payload = response.data.data;
          if (payload.PROMO) {
            keys.PROMO = payload.PROMO;
          }
          if (payload.TRANS) {
            keys.TRANS = payload.TRANS;
          }
          if (payload.OTP) {
            keys.OTP = payload.OTP;
          }
        }
        this.setState({keys, loading: false});
      })
      .catch(err => {
        this.setState({loading: false});
      });
  }
  copyToClipboard = (e, text) => {
    e.stopPropagation()
    try {
      const textField = document.createElement('textarea');
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    } catch (err) {
      alert('Unable to Copy');
    }
  }

  renderCopyable = (text) => {
    return text.map((data, index) => (
      <div key={ data + index }>
        {data}
        <Tooltip title="Copy">
          <IconButton aria-label='copy' onClick={(e) => this.copyToClipboard(e, data)}>
            <ContentCopy />
          </IconButton>
        </Tooltip>
      </div>
    ));
  }


  render() {
    const {keys, loading} = this.state;
    const data = Object.keys(keys).length > 0 ? Object.keys(keys).map(el => ({id: el, keys: keys[el]})) : []
    return (
      <div className='sms-parent-container' style={window.isMobile() ? {padding: 8} : {}}>
        <h4 style={ {color: 'black', fontWeight: 300, fontSize: 18} }>
          Please find below API Documentation Link along with API Key(s) for your account. Please replace them in code while doing integration.
        </h4>
        {loading ? <CircularProgress /> :
          <div>
            {data.length > 0 ?
              <div>
                <a
                  href='https://docs.google.com/document/d/1Vj5F23Rnf1MKdQ_Dsy0I58KUyqZRWIoPyCElcSaAS1I/edit?usp=sharing'
                  target='_blank' style={ {margin: '16px 0', display: 'block', fontSize: 18} } rel="noreferrer">API Doc Link</a>
                <Table
                  column={[
                    {name: 'SMS Type', value: 'id'},
                    {name: 'API Keys', value: 'keys', cell: val => this.renderCopyable(val.map(data => data.key))}
                  ]}
                  data={data}
                />
              </div>
              : <div>No Keys found!</div>
            }
          </div>
        }
      </div>

    );
  }
}
