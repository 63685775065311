import React, {Component} from 'react';
import {Star} from '@mui/icons-material';

import {GAEventTracker} from '../../lib/googleAnalytics';

export default class RequestDemo extends Component {
  state = {
    loading: false
  }
  sumbitContact = () => {
    if (this.state.loading) {
      return
    }
    let name = "Request Free Demo";
    let emailMobile = document.getElementById('emailMobile').value;
    let msg = "Request Free Demo";
    if(!emailMobile) {
      alert("Please enter correct mobile number or email Id");
    } else {
      GAEventTracker({category : 'Website', action : 'Free Demo Request'});
      this.setState({loading: true})
      var that = this
      let xhttp = new XMLHttpRequest();
      xhttp.open("POST", "https://smsapi.edumarcsms.com/api/contact-us", true);
      xhttp.setRequestHeader("Content-Type", "application/json");
      xhttp.onreadystatechange = function() {
        that.setState({loading: false})
        if (this.readyState == 4 && this.status == 200) {
          alert("Thank you for showing interest. Our team will contact you soon!")
        }
      };
      xhttp.send(JSON.stringify({name,mobile:emailMobile,email:emailMobile,msg}));
    }
  }
  render(){
    return(
      <div className="free-demo-conatiner">
        <a className="submit-button common-Button common-Button--default" href="/contact-us">Get Started Now</a>
        <p className="free-credit" style={{display: 'flex', alignItems: 'center', gap: 4}}><Star fontSize='small' /> Get 100 FREE Trial Credits</p>
      </div>
    )
  }
}
