import React from 'react';
import {Card, CardContent, Skeleton} from '@mui/material';
import {PieChart} from '@mui/x-charts/PieChart';
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';

import ErrorMsg from './ErrorMsg';
import Heading from './Heading';
import SubHeading from './SubHeading';
import {getSMSBalance} from './helper';

const SMSBalance = ({dashboardData}) => {
  if (isEmpty(dashboardData)) {
    return <Skeleton variant="rounded" width={'100%'} height={205} />
  }
  return (
    <Card>
      <CardContent>
        <Heading>SMS Balance</Heading>
        <SubHeading>For different SMS types</SubHeading>
        <div className='dashboard-card-container'>
          {dashboardData.success && dashboardData.data?.smsTypeTaken && dashboardData.data?.smsCount ?
            <PieChart
              series={[
                {
                  data: getSMSBalance(dashboardData.data),
                  innerRadius: 30,
                  outerRadius: 50,
                  cx: 50,
                  highlightScope: {faded: 'global', highlighted: 'item'},
                  faded: {innerRadius: 30, additionalRadius: -10, color: 'gray'}
                }
              ]}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: {
                    vertical: 'middle',
                    horizontal: 'right'
                  },
                  itemMarkWidth: 8,
                  itemMarkHeight: 8
                
                }
              }}
            />
            : <ErrorMsg msg={dashboardData?.err?.msg} />
          }
        </div>
      </CardContent>
    </Card>
  )

}

function mapStateToProps(state){
  return{
    dashboardData : state.dashboard.dashboard
  }
}

export default connect(mapStateToProps)(SMSBalance)
