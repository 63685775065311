import React, {Component} from 'react';
import {FileDownload, Search, HighlightOff} from '@mui/icons-material';
import {Button, CircularProgress, TextField, Tooltip, IconButton} from '@mui/material';
import {MobileDateRangePicker, SingleInputDateRangeField} from '@mui/x-date-pickers-pro';
import {isEqual} from 'lodash';
import moment from 'moment';

import Api from '../../api';
import Select from '../../components/Select';
import {SMS_HISTORY_DWNL_FULL_REPORT} from '../../lib/constant';
import {dateFormat, getCookie} from '../../lib/utils';
import Heading from '../NewDashboard/Heading';

const langMap = {TRANS: 'Transactional', PROMO: 'Promotional', OTP: 'OTP', ALL: 'ALL'};

export default class Filter extends Component {
  constructor(props) {
    super(props);
    const startDate = moment().subtract(1, 'months')
    const endDate = moment()
    this.state = {
      smsType: 'ALL',
      startDate,
      endDate,
      mobileNo: '',
      senderIds: props.smsDetails && props.smsDetails.senderIds ? props.smsDetails.senderIds : [],
      showWait: false
    };
    this.senderIds = this.state.senderIds
    this.date = {startDate, endDate}
  }

  handleChange = (event) => {
    this.setState({smsType: event.target.value}, () => this.props.applyFilter(this.state))
  }

  handleDateChange = (date) => {
    this.setState({startDate: date[0], endDate: date[1]})
  }

  formatDate = date => (dateFormat(date, true))

  isSameDate(a, b) {
    return Math.abs(a - b) < (1000 * 3600 * 24) && a.day() === b.day();
  }

  downloadReportsAsExcel = (e) => {
    e.stopPropagation()
    const {startDate, endDate, mobileNo, senderIds} = this.state;
    var increasedEndDate24hour = endDate
    if(this.isSameDate(moment(startDate),moment(endDate)) && !this.isSameDate(moment(endDate),moment())){
      increasedEndDate24hour = moment(endDate);
      increasedEndDate24hour.hour(increasedEndDate24hour.hour()+1);
    }

    const url = `${SMS_HISTORY_DWNL_FULL_REPORT}${startDate.startOf('day').valueOf()}/${endDate.endOf('day').valueOf()}/${mobileNo || 'NA'}/${senderIds?senderIds.join(','):'NA'}`;
    this.setState({showWait: true});
    Api.get(url, `bearer ${ getCookie('userToken') }`).then(response => {
      const a = document.getElementById('download-full-report');
      a.href = `https://${ response.data.data }`;
      a.click();
      this.setState({showWait: false});
    }).catch(err => {
      console.log(err);
      this.setState({showWait: false});
    });
  }

  handleMobileNoChange = (event) => this.setState({mobileNo: event.target.value})

  handleSenderIdChange = (event) => {
    this.setState({senderIds:event.target.value});
  }
  //TODO: change the design of Date picker

  render() {
    const {smsType, startDate, endDate, mobileNo, senderIds, showWait} = this.state;
    const {smsDetails} = this.props;
    const isMobile = window.isMobile();
    return (
      <>
        <Heading>Refine Your SMS Reports</Heading>
        <div className='sms-small-container'>
          <div style={{display: 'flex', gap: 16, alignItems: 'center', flexWrap: 'wrap'}}>
            <MobileDateRangePicker
              value={[moment(startDate), moment(endDate)]}
              slots={{field: SingleInputDateRangeField}}
              onChange={this.handleDateChange}
              onClose={() => {
                if (!moment(startDate).isSame(this.date.startDate) || !moment(endDate).isSame(this.date.endDate)) {
                  this.date={startDate, endDate}
                  this.props.applyFilter(this.state)
                }
              }}
              name="allowedRange"
              disableFuture
              label="Date Range"
              format="DD/MM/YYYY"
              sx={{minWidth: 225}}
            />
            <Select
              value={ smsType }
              fullWidth={ isMobile }
              onChange={ this.handleChange }
              sx={{minWidth: 150}}
              label="SMS Type"
              options={[
                {value: 'ALL', name: 'All'},
                ...smsDetails.smsType && smsDetails.smsType.length > 1 ? smsDetails.smsType.map(el => ({value: el, name: langMap[el]})) : []]
              }
            />
            <Select
              multiple
              label="Headers"
              value={senderIds}
              onChange={this.handleSenderIdChange}
              fullWidth={isMobile}
              onClose={() => {
                if (!isEqual(this.senderIds, this.state.senderIds)) {
                  this.senderIds = this.state.senderIds
                  this.props.applyFilter(this.state)
                }
              }}
              options={smsDetails?.senderIds || []}
              sx={{width: 150}}
            />
            {showWait ? <CircularProgress /> :
              <Button startIcon={<FileDownload />} onClick={this.downloadReportsAsExcel} variant='outlined'>Download</Button>  
            }
            
            <div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto'}}>
              <TextField
                label="Search By Mobile Number"
                placeholder="Enter mobile number"
                id="mobileNo"
                value={mobileNo}
                onChange={this.handleMobileNoChange}
                maxLength="10"
                style={{minWidth: 200}}
                variant="standard"
                size="small"
              />
              <Tooltip title="Search">
                <IconButton aria-label="Search" onClick={e => {e.stopPropagation(); this.props.applyFilter(this.state)}}>
                  <Search />
                </IconButton>
              </Tooltip>
              {mobileNo &&
              <Tooltip title="Clear">
                <IconButton aria-label='Clear Search' onClick={(e) => {e.stopPropagation(); this.setState({mobileNo: ''}, () => this.props.applyFilter(this.state))}}>
                  <HighlightOff />
                </IconButton>
              </Tooltip>
              }
            </div>
          </div>
          <a href='' id='download-full-report' style={ {display: 'none'} } target='_blank' />
        </div>
        
      </>
    )
  }
}
