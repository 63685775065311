import React from 'react';
import {CallOutlined, MailOutline} from '@mui/icons-material';
import {Card, CardContent} from '@mui/material';

import Heading from './Heading';

const Contact = () => {
  const handleIconClick = e => e.stopPropagation()
  return (
    <Card>
      <CardContent>
        <Heading>Contact us</Heading>
        <div className='dashboard-contact-us'>
          <p>
            <MailOutline onClick={handleIconClick} />
            <span>support@edumarcsms.com</span>
          </p>
          <p>
            <CallOutlined onClick={handleIconClick} />
            <span>9164390300, 7892971683</span>
          </p>  
        </div>
      </CardContent>
    </Card>
  )

}

export default Contact