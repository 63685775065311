import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material';

export default class GroupDelete extends Component {
  constructor(){
    super();
    this.state = {
      btnDisabled : false
    }
    this.callbackHandler = this.callbackHandler.bind(this);
  }
  deleteGroup(){
    this.setState({btnDisabled : true});
    this.props.handleDelete();
  }
  callbackHandler = () => this.setState({btnDisabled:false});
  render(){
    let {closeDelete} = this.props;
    let {btnDisabled} = this.state;
    return(
      <Dialog open>
        <DialogTitle>Delete this Group?</DialogTitle>
        <DialogActions>
          <Button onClick={closeDelete}>
            Cancel
          </Button>
          <Button disabled={btnDisabled} onClick={this.deleteGroup} variant='contained' color="error">
            Delete
          </Button>
        </DialogActions>
          
      </Dialog>
    )
  }
}
