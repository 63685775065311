import React from 'react';
import {CircularProgress, Popover} from '@mui/material';

const langMap = {failed : 'Failed', done : 'Delivered', pending : 'Pending', DELIVRD : 'Delivered', INVALID : 'Invalid', visited: 'Visited'};

const SmsReport = ({deliveryUpdates, activeTrackData, anchorEl, showTrackData, closeTrackCampaign}) => {
  const renderDeliveryDetails = (trackCampaign) => {
    const delivered = trackCampaign.DELIVRD ? 
      <p style={{marginTop: 4, color: '#747474', fontSize: 14}}>Delivered<span style={{float: 'right', color: '#333'}}>{trackCampaign.DELIVRD}</span></p> : ''
    delete trackCampaign.DELIVRD
    const renderMap = Object.keys(trackCampaign).map((data) => (
      <p
        key={"trackCampaignonly" + data}
        style={{marginTop: 4, color: '#747474', fontSize: 14}}>{langMap[data] ? langMap[data] : data}<span style={{float: 'right', color: '#333'}}>{trackCampaign[data]}</span></p>
    ))
    renderMap.unshift(delivered)
    return renderMap
  }
  if (showTrackData) {
    var {trackCampaign, urlCampaign} = deliveryUpdates
  }
  return (
    <Popover
      open
      anchorEl={anchorEl}
      anchorOrigin={{horizontal: 'left', vertical: 'top'}}
      targetOrigin={{horizontal: 'right', vertical: 'bottom'}}
      onClose={closeTrackCampaign}
      disableScrollLock
    >
      {
        showTrackData ?
          <div style={{padding: '16px 24px'}}>
            <p style={{fontSize:16, paddingBottom: 10, fontWeight: 500}}>Track Campaign Report</p>
            <p style={{paddingBottom:4,fontSize:14,borderBottom:'1px solid #d4d4d3'}}>Total<span style={{float: 'right'}}>{activeTrackData.contactCount}</span></p>
            {renderDeliveryDetails(trackCampaign)}
            {
              urlCampaign &&
                     <div style={{marginTop: 20}}>
                       <p style={{fontSize:16, paddingBottom: 10, fontWeight: 500}}>Track Smart URL Report</p>
                       {
                         Object.keys(urlCampaign).map((data)=>(
                           <p
                             key={"trackCampaignurl"+data}
                             style={{marginTop:5,color:'#747474',fontSize:14}}
                           >
                             {langMap[data] ? langMap[data] : data}
                             <span style={{float: 'right', color:'#333'}}>{urlCampaign[data]}</span></p>
                         ))
                       }
                     </div>
            }
            <p style={{borderTop: '1px solid #d4dee5', marginTop: 20, paddingTop: 10}}><span style={{color:'#747474'}}>Transaction Id:</span> {activeTrackData.smsTransId}</p>
          </div>
          :   <CircularProgress />
      }

    </Popover>
  )
}

export default SmsReport
