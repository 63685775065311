import React, {Component} from 'react';
import {AssignmentInd, BubbleChart, ChangeHistory, ChromeReaderMode, Email, LockOutlined, Phone, Sms, VerifiedUser, Visibility, VisibilityOff} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from '@mui/material';

import EditDLT from './EditDLT';
import UpdateOperator from './UpdateOperator';
import AddBalance from './addBalance';
import AddSMSType from './addSMSType';
import AddSenderId from './addSenderId';
import ChangeDefaultSenderId from './changeDefaultSenderId';
import ChangePassword from './changePassword';
import ChangeStatus from './changeStatus';
import COSNTANT from './constant';
import DeleteSenderId from './deleteSenderId';

export default class CustomerDetails extends Component {
  state = {
    showAddBalance : false,
    showChangeStatus : false,
    showPassword : false,
    showAddSenderId : false,
    showChangeDefaultSenderId : false,
    showAddSMSType : false,
    showDeleteSenderId: false,
    showUpdateOperatorId: false,
    showEditDLTId: false
  }
  closeAddBalance = () => this.setState({showAddBalance : false});
  closeChangeStatus = () => this.setState({showChangeStatus : false});
  openAddBalance = () => this.setState({showAddBalance : true});
  openAddSMSType = () => this.setState({showAddSMSType : true});
  openChangeStatus = () => this.setState({showChangeStatus : true});
  openAddSenderId = () => this.setState({showAddSenderId : true});
  toggleUpdateOperatorId = () => this.setState(prev => ({showUpdateOperatorId: !prev.showUpdateOperatorId}))
  toggleEditDLTId = () => this.setState(prev => ({showEditDLTId: !prev.showEditDLTId}))
  closeAddSenderId = () => this.setState({showAddSenderId : false});
  closeAddSMSType = () => this.setState({showAddSMSType : false});
  openChangeDefaultSenderId = () => this.setState({showChangeDefaultSenderId : true});
  closeChangeDefaultSenderId = () => this.setState({showChangeDefaultSenderId : false});
  openDeleteSenderId = () => this.setState({showDeleteSenderId : true});
  closeDeleteSenderId = () => this.setState({showDeleteSenderId : false});
  addBalance = (params) =>{
    this.setState({showAddBalance : false});
    this.props.addBalance(params);
  }
  addSMSType = params =>{
    this.setState({showAddSMSType : false});
    this.props.addSMSType(params);
  }
  changeStatus = (params) =>{
    this.setState({showChangeStatus : false});
    this.props.changeStatus(params);
  }
  addSenderId = (params) =>{
    this.setState({showAddSenderId : false});
    this.props.addSenderId(params);
  }
  updateOperator = params => {
    this.toggleUpdateOperatorId()
    this.props.updateOperator(params)
  }
  editDLT = params => {
    this.toggleEditDLTId()
    this.props.editDLT(params)
  }
  changeDefaultSenderId = params =>{
    this.setState({showChangeDefaultSenderId : false});
    this.props.changeDefaultSenderId(params);
  }
  openChangePassword = () => this.setState({showChangePassword : true});
  changePassword = params => {
    this.setState({showChangePassword : false});
    this.props.changePassword(params);
  }
  closeChangePassword = () => this.setState({showChangePassword : false});
  togglePassword = (e) => {e.stopPropagation();this.setState({showPassword:!this.state.showPassword})}
  handleIconClick = e => e.stopPropagation()
  render(){
    let {data} = this.props;
    let {instituteId,name,mobile,email,smsCount,userId,senderId,senderIds,address,status,type,password,smsTypeTaken,keys,info,operatorId, dltPrincipalEntityId} = data;
    let {
      showAddBalance,showChangeStatus,showPassword,showAddSenderId,showChangeDefaultSenderId,showAddSMSType, showChangePassword,
      showDeleteSenderId,showUpdateOperatorId, showEditDLTId
    } = this.state;
    return(
      <div>
        <Dialog open={true} onClose={this.props.closeDetails}>
          <DialogTitle>{name}</DialogTitle>
          <DialogContent dividers>
            <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <VerifiedUser onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Institute ID</p>
                  <p style={{fontSize:16}}>{instituteId}</p>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <VerifiedUser onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>UserId</p>
                  <p style={{fontSize:16}}>{userId}</p>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <LockOutlined onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Password</p>
                  <p style={{fontSize:16}}>{showPassword ? password : "******"}</p>
                </div>
                <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
                  <IconButton onClick={this.togglePassword} aria-label='Password'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Tooltip>
                <Button size="small" onClick={this.openChangePassword}>Change Password</Button>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <Sms onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>SMS Type</p>
                  {
                    smsTypeTaken ?
                      smsTypeTaken.map(val=>(
                        <p key={"smsAPIKey"+val} style={{fontSize:12,marginRight:8}}>{val} : <span style={{fontSize:16}}>{keys[val]}</span></p>
                      ))
                      : null
                  }
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <AssignmentInd onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Header</p>
                  {senderIds.map((data,index)=>(<p
                    style={data==senderId ? {fontWeight:600, fontSize:16}:{fontSize:16}} key={data}>{data}{senderIds.length == index+1 ? "" : ", "}</p>))}
                </div>
                <Button size="small" onClick={this.openAddSenderId}>Add More Header</Button>
                {
                  senderIds.length > 1 ?
                    <Button size="small" onClick={this.openChangeDefaultSenderId}>Change default Header</Button>
                    : null
                }
                {
                  senderIds.length > 1 ?
                    <Button size="small" onClick={this.openDeleteSenderId}>Delete Header</Button>
                    : null
                }
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <AssignmentInd onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Operator ID</p>
                  <p style={{fontSize:16}}>{COSNTANT.OPERATOR_OPTS.find(d => d.value === operatorId)?.name || 'NA'}</p>
                </div>
                <Button size="small" onClick={this.toggleUpdateOperatorId}>Edit Operator ID</Button>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <AssignmentInd onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>DLT ID</p>
                  <p style={{fontSize:16}}>{dltPrincipalEntityId || 'NA'}</p>
                </div>
                <Button size="small" onClick={this.toggleEditDLTId}>Edit DLT ID</Button>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <Sms />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Balance</p>
                  {
                    smsTypeTaken ?
                      smsTypeTaken.map(val=>(
                        <p key={"smsCountDetails"+val} style={{fontSize:12,marginRight:10}}>{val} : <span style={{fontSize:16}}>{smsCount[val]}</span></p>
                      ))
                      : null
                  }
                </div>
                <Button size="small" onClick={this.openAddBalance}>Modify Balance</Button>
                <Button size="small" onClick={this.openAddSMSType}>Add More SMS Types</Button>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <BubbleChart onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Status</p>
                  <p style={{fontSize:16}}>{status}</p>
                </div>
                <Button size="small" onClick={this.openChangeStatus}>Change Status</Button>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <ChangeHistory onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Type</p>
                  <p style={{fontSize:16}}>{type}</p>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <AssignmentInd onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Info</p>
                  <p style={{fontSize:16}}>{info}</p>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <Phone onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Mobile</p>
                  <p style={{fontSize:16}}>{mobile}</p>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <Email onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Email</p>
                  <p style={{fontSize:16}}>{email}</p>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                <ChromeReaderMode onClick={this.handleIconClick} />
                <div>
                  <p style={{color:'rgba(0, 0, 0, 0.54)'}}>Address</p>
                  <p style={{fontSize:16}}>{address}</p>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeDetails}>Close</Button>
          </DialogActions>
        </Dialog>
        {
          showAddBalance ?
            <AddBalance
              data={data}
              addBalance={this.addBalance}
              closeAddBalance={this.closeAddBalance} />
            : null
        }
        {
          showChangeStatus ?
            <ChangeStatus
              status={status}
              changeStatus={this.changeStatus}
              closeChangeStatus={this.closeChangeStatus} />
            : null
        }
        {
          showAddSenderId ?
            <AddSenderId
              addSenderId={this.addSenderId}
              closeAddSenderId={this.closeAddSenderId} />
            : null
        }
        {
          showUpdateOperatorId ?
            <UpdateOperator
              toggleUpdateOperatorId={this.toggleUpdateOperatorId}
              updateOperator={this.updateOperator}
              operatorId={operatorId}
            />
            : null
        }
        {
          showEditDLTId ?
            <EditDLT
              toggleEditDLTId={this.toggleEditDLTId}
              editDLT={this.editDLT}
              dltPrincipalEntityId={dltPrincipalEntityId}
            />
            : null
        }
        {
          showChangeDefaultSenderId ?
            <ChangeDefaultSenderId
              senderIds={senderIds}
              senderId={senderId}
              changeDefaultSenderId={this.changeDefaultSenderId}
              closeChangeDefaultSenderId={this.closeChangeDefaultSenderId} />
            : null
        }
        {
          showAddSMSType ?
            <AddSMSType
              data={data}
              addSMSType={this.addSMSType}
              closeAddSMSType={this.closeAddSMSType} />
            : null
        }
        {
          showChangePassword &&
            <ChangePassword
              data={data}
              changePassword={this.changePassword}
              closeChangePassword={this.closeChangePassword} />
        }
        {
          showDeleteSenderId ?
            <DeleteSenderId
              closeDeleteSenderId={this.closeDeleteSenderId}
              data={data}
              closeDetails={this.props.closeDetailsAndRefresh}
            />
            : null
        }
      </div>

    )
  }
}
