import React, {Component} from 'react';
import {DataUsage, InfoOutlined} from '@mui/icons-material';
import {Button, CircularProgress, Dialog, DialogContent, FormControlLabel, Link, Snackbar, Switch, TextField} from '@mui/material';
import ReactFileReader from 'react-file-reader';
import {connect} from 'react-redux';
import * as XLSX from 'xlsx';

import {getAllBlockedContact} from '../../actions/blockedContact';
import {getSMSDetails, sendSms, updateSMSCount} from '../../actions/sms';
import {getTemplate} from '../../actions/template';
import {getAllUser, getContactGroup} from '../../actions/user';
import Select from '../../components/Select';
import TemplateList from '../../components/TemplateList';
import {SMART_URL} from '../../lib/constant';
import {GAEventTracker, GAModalView} from '../../lib/googleAnalytics';
import {isNotValidPromoTime, isURLValid, sortGroup} from '../../lib/utils';
import Heading from '../NewDashboard/Heading';
import AddNumber from './addNumber';
import ContactGroupList from './contactGroupList';
import RecipientChip from './recipientChip';
import ScheduleDateTime from './scheduleDateTime';
import SelectedContact from './selectedContact';
import SMSBalanceMobile from './smsBalanceM';
import SmsFlashOptionButton from './smsFlashOptionButton';

const labelMap = {TRANS: 'Transactional', PROMO: 'Promotional', OTP: 'OTP'};

class Sms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlashEnabled: false,
      openSnackBar: false,
      openRecipientListDialog: false,
      contactList: {
        'extra': [],
        'contact': [],
        'all': false,
        'group': [],
        'uniqueSelectedNum': 0
      },
      individualList: {},
      openAddNumber: false,
      smsText: '',
      snackBarMsg: '',
      btnDisabled: false,
      showAllSelected: false,
      btnDisabledACG: false,
      readFile: false,
      selectBoxValues: [],
      showScheduling: false,
      smsDetails: props.smsDetails && props.smsDetails.success ? props.smsDetails.data : {},
      senderId: null,
      smsType: null,
      btnDisabledMain: false,
      smartURLTrack: false,
      smartURL: '',
      selectedTemplate: ''
    };
    this.isSmsFlashEnabledFn = this.isSmsFlashEnabledFn.bind(this);
    this.userList = [];
    this.groupList = [];
    this.allUniqueNumCount = 0;
    this.groupListMap = {};
    this.receivedUser = false;
    this.receivedContactGroup = false;
    this.smsSent = false;
    this.receivedSMSDetails = false;
    this.selectAllGroup = {};
    this.isUnicode = false;
    this.smsLengthRange = {
      normal: {
        single: 160,
        multi: 153
      },
      unicode: {
        single: 70,
        multi: 67
      }
    };
    this.smsLength = 0;
    this.smsCountUsed = 0;
    this.senderIds = null;
    this.blockedContact = {};
    this.receivedBlockedContact = false;
    this.blockedFilterNumber = 0;
    this.selectedTemplateId = null;
  }
  componentDidMount() {
    window._epadhaiGTM.push({event: 'smsSection'});
    const {userResponse, contactGroupResponse, blockedContactRes} = this.props;
    this.props.getSMSDetails();
    this.props.getTemplate();
    if (contactGroupResponse && contactGroupResponse.success && contactGroupResponse.data.uniqeContactCount) {
      this.allUniqueNumCount = contactGroupResponse.data.uniqeContactCount;
      this.receivedContactGroup = true;
      this.groupListObj = [];
      this.groupList = contactGroupResponse.data.groups;
      for (const g of this.groupList) {
        this.groupListObj[g.id] = g;
      }
    } else {
      this.props.getContactGroup();
    }
    if (userResponse && userResponse.success) {
      if (userResponse.data.smsUI) {
        this.userList = userResponse.data.contacts;
        this.receivedUser = true;
        const {groups} = userResponse.data;
        for (const g in groups) {
          this.groupListMap[groups[g].id] = groups[g];
        }
      } else {
        this.props.getAllUser(true, true, userResponse);
      }
    } else {
      this.props.getAllUser(true);
    }
    if (blockedContactRes && blockedContactRes.success) {
      this.receivedBlockedContact = true;
      blockedContactRes.data && blockedContactRes.data.list && blockedContactRes.data.list.forEach(d => {
        this.blockedContact[d.mobileNumber] = d.mobileNumber;
      });
    } else {
      this.props.getAllBlockedContact();
    }
  }
  componentWillReceiveProps(nextProps) {
    const {userResponse, sendSmsResponse, smsDetails, contactGroupResponse, blockedContactRes} = nextProps;
    if (!this.receivedUser && this.userList.length == 0 && userResponse && this.props.userResponse != userResponse) {
      if (userResponse.success) {
        this.userList = userResponse.data.contacts;
        this.receivedUser = true;
        const {groups} = userResponse.data;
        for (const g in groups) {
          this.groupListMap[groups[g].id] = groups[g];
        }
        if (this.state.btnDisabledACG) {
          this.setState({btnDisabledACG: false});
        }
      } else if (userResponse.err) {
        alert(userResponse.err.msg);
        this.receivedUser = true;
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
    if (!this.receivedContactGroup && contactGroupResponse) {
      if (contactGroupResponse.success) {
        this.allUniqueNumCount = contactGroupResponse.data.uniqeContactCount;
        this.receivedContactGroup = true;
        this.groupListObj = [];
        this.groupList = contactGroupResponse.data.groups;
        for (const g of this.groupList) {
          this.groupListObj[g.id] = g;
        }
        if (this.state.btnDisabledMain) {
          this.setState({btnDisabledMain: false});
        }
      } else if (contactGroupResponse.err) {
        if (this.state.btnDisabledMain) {
          this.setState({btnDisabledMain: false});
        }
        alert(contactGroupResponse.err.msg);
        this.receivedContactGroup = true;
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
    if (!this.receivedSMSDetails && smsDetails) {
      this.receivedSMSDetails = true;
      if (smsDetails.success) {
        const smsDet = smsDetails.data;
        this.senderIds = smsDet.senderIds;
        this.setState({smsDetails: smsDet, senderId: smsDet.defaultSenderId, smsType: smsDet.defaultSmsType});
      } else if (smsDetails.err) {
        alert(smsDetails.err.msg);
        window.location.reload();
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
    if (this.smsSent && sendSmsResponse) {
      this.smsSent = false;
      const contactList = {
        'extra': [],
        'contact': [],
        'group': [],
        'all': false,
        'uniqueSelectedNum': 0
      };
      let snackBarMsg = 'SMS Submitted Successfully! View SMS Reports for More Details';
      if (sendSmsResponse.err) {
        snackBarMsg = sendSmsResponse.err.msg;
        GAEventTracker({category: 'SMS', action: 'Send SMS Error', label: snackBarMsg});
        alert(snackBarMsg);
        this.setState({btnDisabled: false, snackBarMsg, openSnackBar: true});
      } else if (sendSmsResponse.success) {
        GAEventTracker({category: 'SMS', action: 'Send SMS', value: this.smsCountUsed});
        const {smsDetails} = this.state;
        snackBarMsg = sendSmsResponse.data.msg;
        if (this.blockedFilterNumber) {
          snackBarMsg += `\nFiltered Blocked Numbers - ${ this.blockedFilterNumber }`;
        }
        smsDetails.smsCount = sendSmsResponse.data.smsCount;
        this.props.updateSMSCount(sendSmsResponse.data.smsCount);
        alert(snackBarMsg);
        this.setState({
          btnDisabled: false, snackBarMsg, openSnackBar: true, contactList, individualList: {}, selectBoxValues: [], smsText: '', smsDetails, smartURL: '',
          smartURLTrack: false, isFlashEnabled: false, selectedTemplate: ''
        });
      } else {
        snackBarMsg = 'Something went wrong, Please try again!';
        GAEventTracker({category: 'SMS', action: 'Send SMS Error', label: snackBarMsg});
        alert(snackBarMsg);
        this.setState({btnDisabled: false, snackBarMsg, openSnackBar: true});
      }
    }
    if (!this.receivedBlockedContact && blockedContactRes) {
      if (blockedContactRes.success) {
        this.receivedBlockedContact = true;
        blockedContactRes.data && blockedContactRes.data.list && blockedContactRes.data.list.forEach(d => {
          this.blockedContact[d.mobileNumber] = d.mobileNumber;
        });
      } else if (blockedContactRes.err) {
        alert(blockedContactRes.err.msg);
        this.receivedBlockedContact = true;
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
  }
  getAllContactNum = (contactList) => {
    const mobileList = [];
    for (let i = 0; i < contactList.length; i++) {
      if (mobileList.indexOf(contactList[i].mobile) == -1) {
        mobileList.push(contactList[i].mobile);
      }
    }
    return mobileList.length;
  }
  isSmsFlashEnabledFn(isFlashEnabled) {
    this.setState({isFlashEnabled});
  }
  handleSnackBarClose = () => this.setState({openSnackBar: false});
  handleRecipientListDialogOpen = () => {
    if (this.receivedUser) {
      this.setState({openRecipientListDialog: true, btnDisabledACG: false});
      GAModalView('Select Contact-Group');
    } else {
      this.setState({btnDisabledACG: true});
      alert('We are loading contacts for you, please wait for few moments!');
    }
  }
  handleRecipientListDialogClose = (individualList) => {
    let {contactList, selectBoxValues} = this.state;
    if (this.userList.length == Object.keys(individualList).length) {
      contactList.all = true;
      contactList.contact = [];
      contactList.uniqueSelectedNum = 0;
    } else if (Object.keys(individualList).length) {
      const userDetails = [];
      const mobileList = [];
      for (const userId in individualList) {
        if (mobileList.indexOf(individualList[userId].mobile) == -1) {
          mobileList.push(individualList[userId].mobile);
        }
        userDetails.push(individualList[userId]);
      }
      contactList.contact = userDetails;
      contactList.uniqueSelectedNum = mobileList.length;
    } else {
      contactList.contact = [];
      contactList.uniqueSelectedNum = 0;
    }
    contactList.group = [];
    selectBoxValues = [];
    this.setState({openRecipientListDialog: false, contactList, individualList, selectBoxValues});
    GAEventTracker({category: 'Select Contact-Group', action: 'Ok', value: Object.keys(individualList).length});
  }
  handleRecipientListDialogCloseWithNoAction = () => {
    this.setState({openRecipientListDialog: false});
    GAEventTracker({category: 'Select Contact-Group', action: 'Cancel'});
  }
  sendParams = () => {
    this.blockedFilterNumber = 0;
    let {smsText, contactList, smsType, senderId, isFlashEnabled} = this.state;
    if (smsText.includes(SMART_URL)) {
      smsText = smsText.replace(new RegExp(SMART_URL, 'g'), '{url}');
    }
    const params = {
      message: smsText,
      senderId,
      smsType,
      isFlashEnabled,
      ...(this.selectedTemplateId ? {templateId: this.selectedTemplateId} : {})
    };
    if (contactList.all) {
      params.all = true;
    }
    if (contactList.extra.length) {
      const extra = [];
      for (const num of contactList.extra) {
        extra.push(num.id);
      }
      params.number = extra.filter(d => !this.blockedContact[d]);
      this.blockedFilterNumber = extra.length - params.number.length;
    }
    if (contactList.contact.length) {
      const groupContacts = {};
      const contact = [];
      for (const num of contactList.contact) {
        if (!groupContacts[num.gId]) {
          groupContacts[num.gId] = [num.id];
        } else {
          groupContacts[num.gId].push(num.id);
        }
        contact.push(num.id);
      }
      delete groupContacts['0'];
      params.groupContacts = groupContacts;
      params.contact = contact;
    }
    if (contactList.group.length) {
      params.groups = contactList.group.map(data => { return data.id; });
    }
    return params;
  }
  handleSend = () => {
    const {smsType, smsDetails, smartURLTrack, smartURL} = this.state;
    if (smsType == 'PROMO' && isNotValidPromoTime()) {
      alert('Promotional SMS can be sent only between 10:00 AM to 9:00 PM');
      return;
    }
    if (smsDetails.smsCount[smsType] - smsDetails.scheduledSmsCount[smsType] < this.smsCountUsed) {
      alert("Sorry, You can't send SMS as you have already Scheduled SMS which will not be sent due to lack of sms availablity!");
      return;
    }
    if (this.countContacts() > 50000) {
      alert('Sorry, We can send only 50,000 sms at a time. Please try sending less than 1,00,000 sms only.');
      return;
    }
    const params = this.sendParams();
    if (params.number && params.number.length === 0 && !params.all && !params.contact && !params.groups) {
      alert('Selected numbers are blocked, Please select any other number or delete number from block list to send sms!');
      return;
    }
    if (smartURLTrack) {
      if (isURLValid(smartURL)) {
        params.url = smartURL;
        GAEventTracker({category: 'Smart-Tracking', action: 'Send Smart SMS', value: this.smsCountUsed});
      } else {
        alert('Please enter correct url!');
        return;
      }
    }
    if (params.isFlashEnabled) {
      GAEventTracker({category: 'Flash-SMS', action: 'Send', label: 'SMS'});
    }
    if (params) {
      this.smsSent = true;
      this.setState({btnDisabled: true});
      GAEventTracker({category: 'SMS', action: 'Send SMS Initiated', value: this.smsCountUsed});
      this.props.sendSms(params);
    }
  };
  contactListCallback = (contactList) => {
    const {contact, group} = contactList;
    const mobileList = [];
    const individualList = {};
    const selectBoxValues = [];
    for (const eachContact of contact) {
      individualList[eachContact.id] = eachContact;
      if (mobileList.indexOf(eachContact.mobile) == -1) {
        mobileList.push(eachContact.mobile);
      }
    }
    for (const eachGroup of group) {
      selectBoxValues.push(eachGroup.id);
    }
    contactList.uniqueSelectedNum = mobileList.length;
    this.setState({contactList, individualList, selectBoxValues});
  }
  contactListClearAllCallback = () => {
    const contactList = {
      all: false,
      contact: [],
      extra: [],
      group: [],
      uniqueSelectedNum: 0
    };
    this.setState({contactList, individualList: [], selectBoxValues: []});
  }
  handleOpenAddNumber = () => {
    this.setState({openAddNumber: true});
    GAModalView('Add Extra Number');
  }
  handleAddNumber = (val) => {
    const {contactList} = this.state;
    if (val) {
      contactList.extra = contactList.extra.concat(val);
    }
    this.setState({openAddNumber: false, contactList});
  }
  handleAllSelection = () => {
    let {contactList, selectBoxValues} = this.state;
    if (!this.receivedContactGroup) {
      this.setState({btnDisabledMain: true});
      alert('We are loading contacts for you, please wait for few moments!');
      return;
    } else if (this.allUniqueNumCount == 0) {
      alert('Currently No Contacts Available, Click on Contacts tab on Left to create them!');
      return;
    }
    contactList.all = true;
    contactList.group = [];
    selectBoxValues = [];
    contactList.contact = [];
    this.setState({contactList, selectBoxValues, individualList: {}});
    GAEventTracker({category: 'SMS', action: 'All Contacts Selected'});
  }
  handleShowMoreChip = () => this.setState({showAllSelected: true});
  handleSelectedDialogClose = () => this.setState({showAllSelected: false});
  smsCountFromText = () => {
    const lenCal = this.isUnicode ? this.smsLengthRange.unicode : this.smsLengthRange.normal;
    const len = this.smsLength;
    return len <= lenCal.single ? 1 : (parseInt(len / lenCal.multi) + (len % lenCal.multi == 0 ? 0 : 1));
  }
  handleChange = event => {
    const {contactList} = this.state;
    contactList.group = [];
    const prevSelectBoxValues = this.state.selectBoxValues;
    for (const psb of prevSelectBoxValues) {
      delete this.selectAllGroup[psb];
    }
    
    for (const sb of event.target.value) {
      this.selectAllGroup[sb] = true;
      contactList.group.push({id: sb, name: this.groupListObj[sb].name});
    }
    this.setState({selectBoxValues: event.target.value, contactList});
  }
  calculateSmsText = (val) => {
    let smsLength = 0,
      text = val,
      isUnicode = false;
    const {smsText} = this.state;
    for (let charPos = 0; charPos < text.length; charPos++) {
      switch (text[charPos]) {
      case '[':
      case ']':
      case '\\':
      case '^':
      case '{':
      case '}':
      case '|':
      case '€':
        smsLength += 2;
        break;
      default:
        smsLength += 1;
      }
      if ((!isUnicode && text.charCodeAt(charPos) > 127 && text[charPos] != '€') || text[charPos] === '\t') {
        isUnicode = true;
      }
    }
    this.smsLength = smsLength;
    this.isUnicode = isUnicode;
    if (smsText.includes(SMART_URL) && !val.includes(SMART_URL)) {
      val = smsText.replace(new RegExp(SMART_URL, 'g'), '');
    }
    this.setState({smsText: val});
  }
  handleFiles = files => {
    const reader = new FileReader();
    const numRegex = [new RegExp('^[3-9][0-9]{9}$'), new RegExp('^91[3-9][0-9]{9}$'), new RegExp('^/+91[3-9][0-9]{9}$'), new RegExp('^/+91-[3-9][0-9]{9}$')]
    const {contactList} = this.state;
    const _this = this;
    this.setState({readFile: true});
    reader.onload = function () {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, {type: 'binary'});
      const ws = wb.Sheets[wb.SheetNames[0]];
      let data = XLSX.utils.sheet_to_json(ws, {header: 1, blankrows: false}).flatten();
      const filteredData = [];
      for (const num of data) {
        let i=0;
        for (; i<numRegex.length; i++) {
          if (numRegex[i].test(num)) {
            break
          }
        }
        if (i < numRegex.length) {
          let newNum = Number(num)
          if (i > 0) {
            newNum = Number(num.toString().slice(i+1))
          }
          if (!filteredData.includes(newNum)) {
            filteredData.push(newNum);
            contactList.extra.push({id: newNum.toString(), name: newNum});
          }
        }
      }
      _this.setState({contactList, readFile: false});
      GAEventTracker({category: 'SMS', action: 'Excel Uploaded', value: filteredData.length});
    };
    reader.readAsBinaryString(files[0]);
  }
  countContacts = () => {
    let {contactList, selectBoxValues} = this.state,
      sum = 0;
    if (selectBoxValues.length) {
      for (const g of selectBoxValues) {
        sum += this.groupListObj[g].contactCount;
      }
    }
    return ((contactList.all ? this.allUniqueNumCount : contactList.uniqueSelectedNum) + contactList.extra.length + sum);
  }
  handleToggle = e => this.setState({smsType: e.target.value, smartURLTrack: false, smartURL: '', isFlashEnabled: false})
  handleSelectTemplate = e => {
    const selectedItem = this.props.templateRes.data.find(data => data.id === e.target.value)
    this.selectedTemplateId = selectedItem.id;
    console.log(e.target.value)
    this.setState({selectedTemplate: e.target.value, smsText: selectedItem.text}, () => this.calculateSmsText(selectedItem.text));
  }
  selectFieldHandler = () => {
    GAEventTracker({category: 'SMS', action: 'Select Multi-Groups'});
    if (!this.receivedContactGroup) {
      this.setState({btnDisabledMain: true});
      alert('We are loading contacts for you, please wait for few moments!');
    } else if (this.groupList.length == 0) {
      alert('No Groups Available, Click on Groups Tab on Left to create and add your contacts in those Groups!');
    }
  }
  openUploadExcel = () => {
    GAEventTracker({category: 'SMS', action: 'Upload Excel'});
  }
  handleIconClick = e => e.stopPropagation()
  openScheduleDialog = () => this.setState({showScheduling: true})
  closeScheduleDialog = () => this.setState({showScheduling: false})
  handleScheduleSMS = (scheduleTime) => {
    const params = this.sendParams();
    this.smsSent = true;
    params.time = scheduleTime;
    params.contactCount = this.countContacts();
    params.smsCount = this.smsCountUsed;
    if (params.contactCount > 50000) {
      alert('Sorry, We can send only 1,00,000 sms at a time. Please try sending less than 1,00,000 sms only.');
      return;
    }
    const {smartURLTrack, smartURL} = this.state;
    if (smartURLTrack) {
      if (isURLValid(smartURL)) {
        params.url = smartURL;
        GAEventTracker({category: 'Smart-Tracking', action: 'Schedule Smart SMS', value: this.smsCountUsed});
      } else {
        alert('Please enter correct url!');
        return;
      }
    }
    if (params.isFlashEnabled) {
      GAEventTracker({category: 'Flash-SMS', action: 'Schedule', label: 'SMS'});
    }
    this.setState({btnDisabled: true, showScheduling: false});
    GAEventTracker({category: 'SMS', action: 'Schedule SMS', value: this.smsCountUsed});
    this.props.sendSms(params);
  }
  handleSenderIdChange = e => this.setState({senderId: e.target.value, smsText: '', selectedTemplate: ''})
  isMsgLengthUnderLimit = msgLength => {
    if (this.isUnicode) {
      return msgLength <= 402;
    }
    return msgLength <= 918;
  }
  addCustomMsg = () => {
    const el = document.getElementById('smsText');
    let {smsText} = this.state;
    smsText = `${ smsText.slice(0, el.selectionStart) } ${ SMART_URL } ${ smsText.slice(el.selectionStart) }`;
    this.calculateSmsText(smsText);
    setTimeout(() => {
      this.smsInput.focus();
    }, 100);
    GAEventTracker({category: 'Smart-Tracking', action: 'Add Smart URL'});
  }
  handleSmartURLToggle = (smartURLTrack) => {
    GAEventTracker({category: 'Smart-Tracking', action: 'Toggle Smart URL', label: smartURLTrack ? 'On' : 'Off'});
    if (smartURLTrack == false) {
      this.setState({smartURLTrack, smsText: '', smartURL: ''});
    } else {
      this.setState({smartURLTrack});
    }
  }
  getTemplateList = () => {
    const {templateRes} = this.props;
    const {senderId} = this.state;
    if (templateRes && templateRes.success && templateRes.data && templateRes.data.length) {
      return templateRes.data.filter(data => data.senderId === senderId);
    }
    return [];
  }
  render() {
    const isMobile = window.isMobile();
    const {smsDetails, showScheduling, readFile, selectBoxValues, contactList, smsText,
      btnDisabled, openRecipientListDialog, individualList, openSnackBar, snackBarMsg, openAddNumber, showAllSelected,
      btnDisabledACG, senderId, smsType, btnDisabledMain, smartURLTrack, smartURL, selectedTemplate
    } = this.state;
    const smsCountUsed = this.smsCountFromText() * this.countContacts();
    this.smsCountUsed = smsCountUsed;
    const smsDetailsLen = Object.keys(smsDetails).length;
    const smsTypeSelectedCount = smsDetailsLen ? smsDetails.smsCount[smsType] : 0;
    const isValidMsgLen = this.isMsgLengthUnderLimit(this.smsLength);
    const templateList = this.getTemplateList();
    return (
      <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}} id='sms-ui'>
        <div>
          {
            smsDetailsLen ?
              <>
                {isMobile && <SMSBalanceMobile smsDetails={ smsDetails } />}
                <Heading>Select Header and SMS Type</Heading>
                <div className='sms-small-container-new'>
                  {this.senderIds && this.senderIds.length > 0 &&
                  <Select
                    id="selectedSenderId"
                    value={senderId}
                    onChange={this.handleSenderIdChange}
                    label="Select Header"
                    sx={{minWidth: 170}}
                    options={this.senderIds}
                    required
                  />
                  }
                  {smsDetails.smsType && smsDetails.smsType.length > 0 &&
                  <Select
                    id="selectedSmsType"
                    value={smsType}
                    onChange={this.handleToggle}
                    label="Select SMS Type"
                    sx={{minWidth: 170, marginLeft: 2}}
                    options={smsDetails.smsType.map(el => ({name: labelMap[el], value: el}))}
                    required
                  />
                  }
                </div>
                <Heading>Upload/Add Numbers</Heading>
                <div className='sms-small-container-new' style={{display: 'flex', flexWrap: 'wrap'}}>
                  <div style={{display: 'flex', gap: 16, alignItems: 'center', flexWrap: 'wrap'}}>
                    <Button variant="outlined" onClick={this.handleOpenAddNumber}>Add Mobile Number(s)</Button>
                    <ReactFileReader handleFiles={this.handleFiles} fileTypes=".csv,.xlsx,.xls" elementId='fileUpload'>
                      <Button variant="outlined" onClick={this.openUploadExcel}>Upload Excel (.xlsx,.csv)</Button>
                    </ReactFileReader>
                    <Button variant="outlined" onClick={this.handleAllSelection} disabled={contactList.all || btnDisabledMain}>
                    All Contacts
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={this.handleRecipientListDialogOpen}
                      disabled={contactList.all || contactList.group.length || btnDisabledACG}
                    >
                    Select Contacts
                    </Button>
                    <Select
                      disabled={contactList.all || contactList.contact.length || btnDisabledMain}
                      multiple
                      id="selectBoxValues"
                      value={selectBoxValues}
                      onChange={this.handleChange}
                      label="Select Group(s)"
                      sx={{minWidth: 175, maxWidth: 175}}
                      options={sortGroup(this.groupList).map(el => ({name: el.name, value: el.id}))}
                    />
                    {(btnDisabledACG || btnDisabledMain) && <CircularProgress size={20} thickness={2} />}
                  </div>
                  <div style={{flexBasis: '100%', height: 0}} />
                  {(contactList.all || contactList.extra.length || contactList.contact.length || contactList.group.length) ?
                    <div>
                      <p style={{fontSize: 14, marginTop: 8}}>Total Contact Selected : {this.countContacts()}</p>
                      <RecipientChip
                        renderLess={ true }
                        contactList={ contactList }
                        contactListCallback={ this.contactListCallback }
                        showClearAll={ true }
                        contactListClearAllCallback={ this.contactListClearAllCallback }
                        handleShowMoreChip={ this.handleShowMoreChip }
                      />
                    </div> : null
                  }
                </div>
                <Heading>Choose Template</Heading>
                <div className='sms-small-container-new'>
                  {/* {smsType !== 'OTP' &&
                  <FormControlLabel
                    control={<Switch onChange={e => this.handleSmartURLToggle(e.target.checked)} checked={smartURLTrack}/>}
                    label="Enable Smart Tracking URL"
                  />} */}
                  {smartURLTrack &&
                  <>
                    <TextField
                      label="Enter your webpage URL"
                      value={smartURL}
                      size="small"
                      onChange={ (e) => this.setState({smartURL: e.target.value}) }
                      fullWidth
                      variant='standard'
                    />
                    <p style={{fontSize: 12, marginBottom: 8}}>
                      Note: We will generate short links for each number in format : {' '}
                      <span style={ {color: 'rgb(25, 118, 210)'} }>{SMART_URL}</span>{' '}
                      and sent it for tracking the clicks. All these short links will be redirected to above mentioned link.
                    </p>
                    {smartURL && <Button size="small" onClick={this.addCustomMsg}>Add URL</Button>}
                  </>
                  }
                  <div>
                    {templateList.length > 0 ? 
                      <TemplateList selectedTemplate={selectedTemplate} templateList={templateList} onChange={this.handleSelectTemplate} showMsg />
                      :
                      <p style={{color: '#d32f2f', fontSize: 14}}>
                        No template found for selected Header, Please add template from <Link href='/template' color="error">Manage Templates</Link>.
                      </p>
                    }
                    <TextField
                      id="smsText"
                      label="Enter SMS Text"
                      multiline
                      rows={4}
                      value={ smsText }
                      onChange={ (e) => this.calculateSmsText(e.target.value) }
                      inputRef={ (input) => this.smsInput = input }
                      fullWidth
                      sx={{mt: 1}}
                      required
                      variant='standard'
                      inputProps={{maxLength: 918}}
                    />
                  </div>
                  <div style={{display: 'flex', marginTop: 8, alignItems: 'center', ...(isMobile ? {flexWrap: 'wrap'} : {})}}>
                    <div>
                      {smsText.length > 0 &&
                        <div>
                          <p style={{fontSize: 12}}>Character count: {this.smsLength}</p>
                          <p style={{fontSize: 12}}>SMS Credit: {smsCountUsed}</p>
                        </div>}
                      {!isValidMsgLen && <p style={{fontSize: 14, color: '#d32f2f', marginTop: 4}}>
                        {`*Message Length Exceeds allowed Limit, Kindly limit your message to ${ this.isUnicode ? '402' : '918' } characters.`}
                      </p>}
                      {smsTypeSelectedCount < smsCountUsed && <p style={{fontSize: 14, color: '#d32f2f', marginTop: 4}}>
                        Not Sufficient amount to Send SMS! Please Recharge Soon. Contact your Agent. You can mail/call us also :
                        <br />
                        Email ID : support@edumarcsms.com
                        <br />
                        Mobile : 9164390300
                      </p>}
                      {smsDetails.smsType.length === 2 && <p style={{fontSize: 14, color: '#d32f2f', marginTop: 4}}>Note : You have selected {smsType} type!</p>}
                      {this.isUnicode && <p style={{fontSize: 12, color: '#d32f2f', marginTop: 4}}>*Regional Language/Unicode is used</p>}
                    </div>
                    {isMobile ? <div style={{flexBasis: '100%', height: 0}} /> : null}
                    <div style={{marginLeft: 'auto', display: 'flex', gap: 16, alignItems: 'center'}}>
                      {smsType !== 'OTP' && <SmsFlashOptionButton
                        isSmsFlashEnabledFn={ this.isSmsFlashEnabledFn }
                        pageType='SMS'
                      />}
                      <Button
                        variant="contained"
                        onClick={this.handleSend}
                        disabled={ !smsCountUsed || smsTypeSelectedCount < smsCountUsed || btnDisabled || !smsText || !isValidMsgLen || !selectedTemplate }
                      >
                        Send Now
                      </Button>
                      {/* <Button
                        variant="contained"
                        onClick={this.openScheduleDialog}
                        disabled={ !(smsCountUsed && smsTypeSelectedCount >= smsCountUsed && !btnDisabled && smsText && isValidMsgLen && selectedTemplate) }
                      >
                        Schedule SMS
                      </Button> */}
                      {btnDisabled && <CircularProgress size={ 20 } thickness={ 4 } />}
                    </div>
                  </div>
                </div>
                <div style={{fontSize: 14}}>
                  <InfoOutlined sx={{fontSize: 14, mr: 1}} onClick={this.handleIconClick} />
                  As per TRAI Notice, kindly get your content templates approved at DLT portal and upload it
                  <Link href='/template' sx={{ml: 1}}>here</Link>. Please contact us incase you need any help.
                </div>
              </>
              :
              <Dialog open>
                <DialogContent sx={{width: 100}}>
                  <div>
                    <CircularProgress />
                  </div>
                </DialogContent>
              </Dialog>
          }
        </div>
        {readFile &&
        <Dialog open>
          <DialogContent sx={{width: 325}}>
            <div>
              <p style={ {width: 200, margin: '0 auto', textAlign: 'center', marginBottom: 10} }>
                <DataUsage onClick={this.handleIconClick} />
              </p>
              <p>Please wait while we process your file!</p>
            </div>
          </DialogContent>
        </Dialog>
        }
        {openRecipientListDialog &&
        <ContactGroupList
          handleRecipientListDialogClose={ this.handleRecipientListDialogClose }
          handleRecipientListDialogCloseWithNoAction={ this.handleRecipientListDialogCloseWithNoAction }
          individualList={ JSON.parse(JSON.stringify(individualList)) }
          groupList={ this.groupList }
          groupListMap={ this.groupListMap }
          userList={ this.userList }
        />}
        {openSnackBar &&
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open
          onClose={this.handleSnackBarClose}
          autoHideDuration={ 4000 }
          message={snackBarMsg}
        />
        }
        {openAddNumber && <AddNumber handleAddNumber={ this.handleAddNumber } />}
        {showAllSelected &&
        <SelectedContact
          contactList={ contactList }
          handleSelectedDialogClose={ this.handleSelectedDialogClose }
          contactListCallback={ this.contactListCallback }
        />
        }
        {showScheduling &&
        <ScheduleDateTime
          closeScheduleDialog={ this.closeScheduleDialog }
          isPromotional={ smsType == 'PROMO' }
          handleScheduleSMS={ this.handleScheduleSMS }
        />
        }
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    userResponse: state.userResponse.userResponse,
    sendSmsResponse: state.smsResponse.sendSmsResponse,
    smsDetails: state.smsResponse.smsDetailsResponse,
    contactGroupResponse: state.userResponse.contactGroupResponse,
    blockedContactRes: state.blockedContacts.blockedContact,
    templateRes: state.template.templateList
  };
}
export default connect(mapStateToProps, {getAllUser, sendSms, getSMSDetails, getContactGroup, updateSMSCount, getAllBlockedContact, getTemplate})(Sms);
