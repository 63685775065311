import React from 'react';
import {KeyboardArrowLeft} from '@mui/icons-material';
import {AppBar, IconButton, Toolbar, Typography} from '@mui/material';
import {withRouter} from 'react-router-dom';

import {BRAND_LOGO_LINK} from '../../lib/constant';

const MenuBarOnly = ({children, history}) => {
  const isMobile = window.isMobile();
  return(
    <div>
      <AppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={(e) => {e.stopPropagation();history.push("/sms")}}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            Help and Guidelines
          </Typography>
          {isMobile ? null :
            <div style={{color:'white',marginTop:8}}>
              <a style={{color:'white'}} href="/" target="_blank">
                <img
                  src={BRAND_LOGO_LINK}
                  alt=''
                  style={{width:50,height:50,verticalAlign:'middle'}} />
                <p style={{marginRight:50,fontSize:16,display:'inline-block'}}>edumarc</p></a></div>}
        </Toolbar>
      </AppBar>
      <div style={{overflow:'auto',maxHeight:'calc(100vh - 64px)'}}>
        {children}
      </div>
    </div>
  )
}


export default withRouter(MenuBarOnly)
