import { Map } from 'immutable';

import {
	GET_SMS_HISTORY, SMS_HISTORY_STATUS, SMS_HISTORY_DWNL_REPORT, SMS_HISTORY_DWNL_FILTER_REPORT, GET_TRACK_CAMPAIGN, SMS_HISTORY_SMART_STATUS, GET_ALL_SMS_HISTORY
} from '../lib/constant';

const initialState = Map({
	getHistoryResponse: {},
	getAllHistoryResponse: {},
	getSingleHistoryResponse: {},
	getSingleSmartHistoryResponse: {},
	getTrackCampaignResponse: {},
	getDownloadReportResponse: {},
	getSmartDownloadReportResponse: {},
	
});

export default function(state = initialState , action ) {
	switch(action.type){
		case GET_SMS_HISTORY :
			return {...state,getHistoryResponse:action.payload};
		case GET_ALL_SMS_HISTORY:
			return {...state,getAllHistoryResponse:action.payload};
		case SMS_HISTORY_STATUS :
			return {...state,getSingleHistoryResponse:action.payload};
		case SMS_HISTORY_SMART_STATUS :
			return {...state,getSingleSmartHistoryResponse:action.payload};
		case SMS_HISTORY_DWNL_REPORT :
			return {...state,getDownloadReportResponse:action.payload};
		case SMS_HISTORY_DWNL_FILTER_REPORT :
			return {...state,getSmartDownloadReportResponse:action.payload};
		case GET_TRACK_CAMPAIGN :
			return {...state,getTrackCampaignResponse:action.payload};
		default :
			return state;
	}
}
