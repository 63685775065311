import React, {Component} from 'react';
import {ArrowDropDown, ArrowForward, Call} from '@mui/icons-material';
import {Link} from 'react-router-dom';

import {BRAND_LOGO_LINK} from '../../lib/constant';
import {GAEventTracker, fireTracking} from '../../lib/googleAnalytics';

const headerMenu = [
  {link : "/bulk-sms-services", name : "Services"},
  {link : "/bulk-sms-pricing", name : "Pricing"},
  {link : "/bulk-sms-api", name : "API Integration"},
  {link : "/bulk-sms-features", name : "Features"},
  {link : "/contact-us", name : "Contact Us"}
]
export default class Header extends Component{
  constructor(){
    super();
    this.state={
      showPopup : false
    }
  }
  componentDidMount(){
    fireTracking();
  }
  showMenu = () => {this.setState({showPopup:true})}
  hideMenu = () => {this.setState({showPopup:false})}
  render(){
    let {showPopup} = this.state;
    return(
      <>
        <div className='top-header'>
          <div className='top-header-container'>
            <p>DLT registration is mandatory by TRAI for all business to send SMS. We are here to help you. <Link to="/contact-us" className='link-contact '>Contact Us</Link></p>
            <p className='missed-call'>
              <span className="smsTo" style={{display: 'flex', alignItems: 'center', gap: 4}}>
                <Call onClick={e => e.stopPropagation()} fontSize="small" /><span className="dark">9164390300</span>
              </span></p>
          </div>
        </div>
        <header className={"globalNav noDropdownTransition ed-white " + this.props.headerClass}>
          <div className="container-lg">
            <ul className="navRoot">
              <li className="navSection logo">
                <Link className="rootLink item-home bold" to='/'>
                  <img
                    src={BRAND_LOGO_LINK}
                    style={{width: 65, height: 65, verticalAlign: 'middle', marginRight: '-8px'}} alt="edumarc" />
                  <span className='brand-name'>edumarc</span>
                </Link>
              </li>
              <li className="navSection secondary">
                <ul>
                  <li className="rootLink services">
                    <Link
                      to="/bulk-sms-services"
                      className={`colorize ${['Services','OTPSMS', 'ContactManagement', 'PromotionalSMS', 'TransactionalSMS'].includes(this.props.activeMenu) ? "activeMenu" : ""}`}
                      style={{display: 'flex', alignItems: 'center'}}
                    >
                      Services <ArrowDropDown onClick={e => e.stopPropagation()} />
                    </Link>
                    <div className="dropdown-content">
                      <Link to="/transactional-bulk-sms" className={`rootLink item-home colorize ${this.props.activeMenu === 'TransactionalSMS' ? 'bold' : ''}`}>
                        Transactional Bulk SMS
                      </Link>
                      <Link to="/promotional-bulk-sms" className={`rootLink item-home colorize ${this.props.activeMenu === 'PromotionalSMS' ? 'bold' : ''}`}>
                        Promotional Bulk SMS
                      </Link>
                      <Link to="/otp-sms-services" className={`rootLink item-home colorize ${this.props.activeMenu === 'OTPSMS' ? 'bold' : ''}`}>OTP SMS</Link>
                      <Link to="/bulk-sms-api" className="rootLink item-home colorize">SMS API Integration</Link>
                      <Link to="/contact-management-system" className={`rootLink item-home colorize ${this.props.activeMenu === 'ContactManagement' ? 'bold' : ''}`}>
                        Contact Management System
                      </Link>
                    </div>
                  </li>
                  <li className="rootLink services">
                    <Link to="/bulk-sms-pricing" className={`colorize ${"Pricing" == this.props.activeMenu ? "activeMenu" : ""}`}>Pricing</Link>
                  </li>
                  <li className="rootLink services">
                    <Link to="/bulk-sms-api" className={`colorize ${"API Integration" == this.props.activeMenu ? "activeMenu" : ""}`}>API Integration</Link>
                  </li>
                  <li className="rootLink services">
                    <Link to="/bulk-sms-features" className={`colorize ${"Features" == this.props.activeMenu ? "activeMenu" : ""}`}>Features</Link>
                  </li>
                </ul>
              </li>
              <li className="navSection tertiary">
                <ul>
                  <li className="rootLink services contact-us">
                    <Link to="/contact-us" className={`colorize ${"Contact Us" == this.props.activeMenu ? "activeMenu" : ""}`}>Contact Us</Link>
                  </li>
                  <li className="rootLink services login-svc" style={{paddingRight:0}}>
                    <a className="colorize" href="/login" onClick={e=>{GAEventTracker({category : 'Website', action : 'Sign-In'})}}>
                      Login
                    </a>
                  </li>
                </ul>
              </li>
              <li className={showPopup ? "navSection mobile globalPopupActive" : "navSection mobile"}>
                <a className="rootLink item-mobileMenu colorize" onClick={(e)=>{e.preventDefault();this.showMenu();}}><h2>Menu</h2></a>
                <div className="popup">
                  <div className="popupContainer" onClick={()=>{this.hideMenu();}}>
                    <a className="popupCloseButton"></a>
                    <div className="mobileSecondaryNav">
                      <ul>
                        {
                          headerMenu.map(data=>(
                            <Link to={data.link} className="bold" key={data.name}>{data.name}</Link>
                          ))
                        }
                      </ul>
                    </div>
                    <a className="mobileSignIn" href="/login" target="_blank" style={{display: 'flex', alignItems: 'center', gap: 8}}>
                      Login <ArrowForward onClick={e => e.stopPropagation()} fontSize="small" />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </header>
      </>
    )
  }
}
