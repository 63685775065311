import React, {Component} from 'react';
import {Clear, Delete, HighlightOff, Search} from '@mui/icons-material';
import {Button, CircularProgress, Dialog, DialogContent, IconButton, Snackbar, TextField, Tooltip} from '@mui/material';
import {throttle} from 'lodash';
import {connect} from 'react-redux';

import {addBlockNumber, deleteBlockNumber, getAllBlockedContact} from '../../actions/blockedContact';
import Table from '../../components/Table';
import AddNumber from './addNumber';
import BulkContactUpload from './bulkContactUpload';
import UserDelete from './delete';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      openSnackBar: false,
      openAdd: false,
      userList: [],
      autoCompleteValue: '',
      showWait: false,
      deleteList: [],
      showBulkDelete: false,
      currentUserDetails: ''
    };
    this.receivedUser = false;
    this.addAction = false;
    this.snackBarMsg = '';
    this.userList = [];
    this.userListMap = {};
    this.throttleInput = throttle(this.throttleInput.bind(this), 300);
    this.autoComplete = this.autoComplete.bind(this);
    this.loadData = false;
    this.bulkDeleteAction = false;
  }
  componentWillMount() {
    const {blockedContactsRes} = this.props;
    if (blockedContactsRes && blockedContactsRes.success) {
      const userList = blockedContactsRes.data.list;
      this.receivedUser = true;
      this.userList = userList;
      userList.forEach(d => {
        this.userListMap[d.mobileNumber] = d.mobileNumber;
      });
      this.setState({userList});
    } else {
      this.props.getAllBlockedContact();
    }
  }
  componentWillReceiveProps(nextProps) {
    const {blockedContactsRes, addBlockedContactsRes, bulkDeleteResponse} = nextProps;
    if (!this.receivedUser &&
      this.state.userList &&
      blockedContactsRes &&
      blockedContactsRes !== this.props.blockedContactsRes
    ) {
      if (blockedContactsRes.success) {
        let userList = blockedContactsRes.data.list;
        userList = userList.map(el => ({...el, id: el.mobileNumber}))
        this.receivedUser = true;
        this.userList = userList;
        userList.forEach(d => {
          this.userListMap[d.mobileNumber] = d.mobileNumber;
        });
        if (!this.loadData) {
          this.setState({userList});
        } else {
          this.loadData = false;
          this.setState({userList, showWait: false});
        }
      } else if (blockedContactsRes.err) {
        this.receivedUser = true;
        alert(blockedContactsRes.err.msg);
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
      return;
    }
    if (this.addAction && addBlockedContactsRes) {
      this.addAction = false;
      if (addBlockedContactsRes.success) {
        this.snackBarMsg = 'Contacts Blocked Successfully!';
        this.receivedUser = false;
        this.loadData = true;
        this.props.getAllBlockedContact();
        this.setState({showWait: true, openAdd: false, openSnackBar: true});
      } else if (addBlockedContactsRes.err) {
        this.snackBarMsg = addBlockedContactsRes.err.msg;
        alert(addBlockedContactsRes.err.msg);
        this.setState({openSnackBar: true});
      } else {
        this.snackBarMsg = 'Something went wrong, Please try again!';
        alert('Something went wrong, Please try again!');
        this.setState({openSnackBar: true});
      }
      return;
    }
    if (this.bulkDeleteAction && bulkDeleteResponse) {
      this.bulkDeleteAction = false;
      if (bulkDeleteResponse.success) {
        this.snackBarMsg = 'All Selected blocked contacts deleted Successfully!';
        this.receivedUser = false;
        this.loadData = true;
        this.props.getAllBlockedContact();
        this.setState({showWait: true, showDelete: false, openSnackBar: true, showBulkDelete: false, deleteList: []});
      } else if (bulkDeleteResponse.err) {
        this.snackBarMsg = bulkDeleteResponse.err.msg;
        alert(bulkDeleteResponse.err.msg);
        this.setState({openSnackBar: true});
      } else {
        this.snackBarMsg = 'Something went wrong, Please try again!';
        alert('Something went wrong, Please try again!');
        this.setState({openSnackBar: true});
      }
    }
  }
  handleSnackBarClose = () => {
    this.snackBarMsg = '';
    this.setState({openSnackBar: false});
  }
  handleAddUser = (params) => {
    if (params && params.length) {
      const filteredNum = params.filter(d => !this.userListMap[d]);
      if (filteredNum.length) {
        this.addAction = true;
        this.setState({showWait: true, openAdd: false});
        this.props.addBlockNumber({blockedNumberList: filteredNum});
      } else {
        alert('All selected numbers are already blocked!');
        this.setState({openAdd: false});
      }
    } else {
      this.setState({openAdd: false});
    }
  }
  handleBulkDelete = (e) => {
    e.stopPropagation()
    if (this.state.deleteList.length) {
      this.setState({showDelete: true});
    } else {
      alert('Select Contacts to Delete!');
    }
  }
  handleDelete = () => {
    let {deleteList, currentUserDetails, showBulkDelete} = this.state;
    this.bulkDeleteAction = true;
    if (!showBulkDelete) {
      deleteList = [currentUserDetails];
    }
    this.setState({showWait: true, showBulkDelete: false});
    this.props.deleteBlockNumber({blockedNumberList: deleteList});
  }
  showBulkDelete = (e) => {e.stopPropagation();this.setState({showBulkDelete: true})};
  clearBulkDelete = (e) => {e.stopPropagation();this.setState({showBulkDelete: false, deleteList: [], deleteAllCheck: false})};
  closeDelete = () => {
    this.setState({showDelete: false, currentUserDetails: null});
  }
  updateCheck = (id) => {
    // console.log(id, row)
    const {deleteList} = this.state;
    const idIndex = deleteList.indexOf(id);
    if (idIndex == -1) {
      deleteList.push(id);
    } else {
      deleteList.splice(idIndex, 1);
    }
    this.setState({deleteList});
  }
  updateCheckAll = () => {
    const {userList, deleteList} = this.state;
    if (deleteList.length === userList.length) {
      this.setState({deleteList: []});
    } else {
      for (const delContact of userList) {
        if (!deleteList.includes(delContact.mobileNumber)) {
          deleteList.push(delContact.mobileNumber);
        }
        this.setState({deleteList});
      }
    }
  }
  addUser = (e) => {
    e.stopPropagation()
    this.setState({openAdd: true});
  }
  closeAddUser = () => {
    this.setState({openAdd: false});
  }
  openDelete = (e, mobileNumber) => {
    e.stopPropagation()
    this.setState({showDelete: true, currentUserDetails: mobileNumber});
  }
  autoComplete = (e) => {
    this.setState({autoCompleteValue: e.target.value});
    this.throttleInput(e.target.value);
  }
  throttleInput = (val) => {
    const userList = this.userList.filter(data => data.mobileNumber.includes(val));
    this.setState({userList});
  }
  removeAutoCompleteValue = (e) => {
    e.stopPropagation()
    this.setState({autoCompleteValue: '', userList: this.userList});
  }
  render() {
    const {showWait, autoCompleteValue, userList, openSnackBar, openAdd, showBulkDelete, deleteList, showDelete, currentUserDetails} = this.state;
    const isMobile = window.isMobile();
    const rightIconButtons = (mobileNumber) => {
      return (
        <Tooltip title="Remove from Blocked numbers">
          <IconButton aria-label='Delete' onClick={e => this.openDelete(e, mobileNumber)}>
            <Delete />
          </IconButton>
        </Tooltip>
      );
    };
    return (
      <>
        <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
          <div className='sms-small-container'>
            <div style={{display: 'flex', alignItems: 'start', gap: 8}}>
              {this.userList.length > 0 &&
                <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                  <p style={{fontSize: 16, flex: 'none'}}>{`Total Blocked Numbers (${ this.userList.length })`}</p>
                  <div style={{display: 'flex', alignItems: 'center', gap: 8, width: isMobile ? 300 : 350}}>
                    <Search onClick={e => e.stopPropagation()} />
                    <TextField
                      label={isMobile ? "Search Contact" : "Search Contact By Name/Number"}
                      size="small"
                      fullWidth
                      value={autoCompleteValue}
                      variant="standard"
                      onChange={this.autoComplete}
                    />
                    {
                      autoCompleteValue ?
                        <Tooltip title="Clear Search">
                          <IconButton aria-label='Clear Search' onClick={this.removeAutoCompleteValue}>
                            <HighlightOff />
                          </IconButton>
                        </Tooltip>
                        : null
                    }
                  </div>
                </div>
              }
              <div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto', justifyContent: 'end', flexWrap: 'wrap'}}>
                <BulkContactUpload applyBulk={ this.handleAddUser } />
                <Button onClick={this.addUser} variant='outlined'>Block Number</Button>
              </div>
            </div>
            {
              this.userList.length ?
                <div>
                  {userList.length ?
                    <Table
                      column={[
                        {name: 'Mobile', value: 'mobileNumber'},
                        ...(showBulkDelete ? [] : [{name: 'Actions', value: 'mobileNumber', cell: val => rightIconButtons(val)}])
                      ]}
                      data={userList}
                      enableCheckbox={showBulkDelete}
                      handleSelect={this.updateCheck}//row.id might be missing
                      selected={deleteList}
                      onSelectAllClick={this.updateCheckAll}
                      showPagination
                      defaultPageSize={100}
                      actionEl={<div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto', flexWrap: 'wrap', justifyContent: 'end'}}>
                        {showBulkDelete ?
                          <>
                            <Button startIcon={<Delete />} onClick={this.handleBulkDelete} variant="outlined">Confirm Delete</Button>
                            <Button startIcon={<Clear />} onClick={this.clearBulkDelete} variant='outlined'>Clear Bulk Delete</Button>
                          </> : <Button startIcon={<Delete />} onClick={this.showBulkDelete} variant='outlined'>Bulk Delete</Button>}
                      </div>}
                    />
                    :
                    <p style={ {margin: 30, fontSize: 16} }>No blocked numbers are available with searched keyword!</p>
                  }
                </div>
                : <div>
                  {
                    this.receivedUser ?
                      <p style={ {margin: 50, fontSize: 20, color: '#EF5350'} }>Currently No Numbers are Blocked! Please add to Block Contacts!</p>
                      :
                      <CircularProgress size={ 80 } thickness={ 5 } />
                  }
                </div>
            }
          </div>
        </div>
        {
          openSnackBar ?
            <Snackbar
              open={ openSnackBar }
              message={ this.snackBarMsg }
              autoHideDuration={ 4000 }
              onClose={this.handleSnackBarClose}
            />
            : null
        }
        {
          openAdd ?
            <AddNumber handleAddNumber={ this.handleAddUser } />
            : null
        }
        {
          showWait ?
            <Dialog open>
              <DialogContent sx={{width: 100}}>
                <div>
                  <CircularProgress />
                </div>
              </DialogContent>
            </Dialog>
            : null
        }
        {
          showDelete ?
            <UserDelete
              showDelete={ showDelete }
              bulkDelete={ showBulkDelete }
              number={ currentUserDetails }
              handleDelete={ this.handleDelete }
              closeDelete={ this.closeDelete }
            />
            : null
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    blockedContactsRes: state.blockedContacts.blockedContact,
    addBlockedContactsRes: state.blockedContacts.addBlockContact,
    bulkDeleteResponse: state.blockedContacts.deleteBlockContact
  };
}
export default connect(mapStateToProps, {addBlockNumber, getAllBlockedContact, deleteBlockNumber})(User);
