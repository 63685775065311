import React, {Component} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

import {connect} from 'react-redux';
import {getSMSDetails} from '../../actions/sms';
import {editTemplate, postTemplate} from '../../actions/template';
import Select from '../../components/Select';

class TemplateForm extends Component {
  constructor(props) {
    super(props);
    const {data} = props;
    this.state = {
      name: data ? data.name : '',
      text: data ? data.text : '',
      senderId: data ? data.senderId : '',
      id: data ? data.id : '',
      smsDetails: props.smsDetails && props.smsDetails.success ? props.smsDetails.data : {},
      btnDisabled: false
    };
    this.receivedSMSDetails = true;
    this.receivedPostTemplate = true;
    this.receivedEditTemplate = true;
  }
  componentDidMount() {
    if (!this.state.smsDetails.senderIds) {
      this.receivedSMSDetails = false;
      this.props.getSMSDetails();
    }
  }
  componentWillReceiveProps(nextProps) {
    const {smsDetails, postTemplateRes, editTemplateRes} = nextProps;
    if (this.receivedSMSDetails === false && smsDetails) {
      this.receivedSMSDetails = true;
      if (smsDetails.success) {
        this.setState({smsDetails: smsDetails.data});
      } else if (smsDetails.err) {
        alert(smsDetails.err.msg);
        window.location.reload();
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
    if (this.receivedPostTemplate === false && postTemplateRes) {
      this.receivedPostTemplate = true;
      if (postTemplateRes.success) {
        alert('Template Added successfully!');
        this.props.handlePostTemplate();
      } else if (postTemplateRes.err) {
        alert(postTemplateRes.err.msg);
        this.setState({btnDisabled: false});
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
    if (this.receivedEditTemplate === false && editTemplateRes) {
      this.receivedEditTemplate = true;
      if (editTemplateRes.success) {
        alert('Template Edited successfully!');
        this.props.handlePostTemplate();
      } else if (editTemplateRes.err) {
        alert(editTemplateRes.err.msg);
        this.setState({btnDisabled: false});
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
  }
  handleInput = (id, name) => this.setState({[id]: name});
  handleChange = (e) => this.setState({senderId: e.target.value});

  handleSaveBtn = () => {
    const {name, text, senderId, id} = this.state;
    if (name && text && senderId && id) {
      this.setState({btnDisabled: true});
      if (this.props.data) {
        this.receivedEditTemplate = false;
        this.props.editTemplate(id, {name, text, senderId});
      } else {
        this.receivedPostTemplate = false;
        this.props.postTemplate({name, text, senderId, id});
      }
    } else {
      alert('All fields are mandatory!');
    }
  }

  render() {
    const {name, senderId, smsDetails, id, text, btnDisabled} = this.state;
    const {closeHandler, data} = this.props;
    return (
      <div>
        <Dialog
          open
          onClose={closeHandler}
        >
          <DialogTitle>
            Add Template
          </DialogTitle>
          <DialogContent dividers sx={{display: 'flex', flexDirection: 'column', gap: 1.5}}>
            <Select
              id="selectedSenderId"
              value={senderId}
              onChange={this.handleChange}
              label="Select Header"
              sx={{minWidth: window.isMobile() ? 300 : 400}}
              options={smsDetails.senderIds}
              required
            />
            <TextField
              label="Template Name for Reference"
              size="small"
              fullWidth
              value={name}
              onChange={ (e) => { this.handleInput('name', e.target.value); } }
              required
              variant="standard"
            />
            <TextField
              label="Template ID provided by DLT"
              size="small"
              fullWidth
              value={id}
              disabled={ !!data }
              onChange={ (e) => { this.handleInput('id', e.target.value); } }
              required
              variant="standard"
            />
            <TextField
              label="Template content exactly as per DLT"
              multiline
              rows={3}
              id="text"
              size="small"
              fullWidth
              value={text}
              onChange={ (e) => { this.handleInput('text', e.target.value); } }
              required
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={ btnDisabled } onClick={closeHandler}>Cancel</Button>
            <Button disabled={ btnDisabled } onClick={this.handleSaveBtn} variant='contained'>Save</Button>
            {btnDisabled && <CircularProgress size={ 20 } thickness={ 2 } />}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    smsDetails: state.smsResponse.smsDetailsResponse,
    postTemplateRes: state.template.postTemplate,
    editTemplateRes: state.template.editTemplate
  };
}
export default connect(mapStateToProps, {getSMSDetails, postTemplate, editTemplate})(TemplateForm);
