/* eslint-disable max-len */
import React from 'react';
import {Helmet} from "react-helmet";

import {
  checkedLogo,
  dashboardLogo, deliveryLogo,
  lowPriceLogo,
  sendToDNDLogo, senderIdLogo,
  smsScheduleLogo, supportLogo,
  transSMSLogo,
  undertakingLogo,
  unlimitedLogo
} from './WebLogo';
import Footer from './footer';
import Header from './header';


const TransactionalSMS = () => (
  <div>
    <Helmet>
      <title>Transactional Bulk SMS | Edumarc</title>
      <meta name="keywords" content="bulk sms app, DND filter message, DND filter sms, bulk sms DND filter, send bulk sms, bulk sms service, online sms campaigns, online marketing sms, bulk sms service" />
      <meta name="description" content="Send Promotional, Transactional, Marketing and Business Bulk SMS from excel sheet and API." />
    </Helmet>
    <Header headerClass="activeBlue" activeMenu='TransactionalSMS' />
    <div className="promotiona-section container">
      <div className="svg-icon" dangerouslySetInnerHTML={{__html: transSMSLogo}}></div>
      <h1>Send your Transactional SMS all over India</h1>
      <h2>Best sms marketing tool in Industry to target bulk audience with fast, reliable and deliverable tool.</h2>
      <div className="promotional-features">
        <p>Benefist you will get with our system</p>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: lowPriceLogo}}></div>
                <p>Low Price</p>
              </div>
              <p>Guarantees lowest price for the bestest services we provide. High services = low price is the key-feature. Find a low price & we will match it.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: supportLogo}}></div>
                <p>Free Setup, Service & Support</p>
              </div>
              <p>We are very lenient :), so we just charge for the SMSs you buy and nothing extra. No setup fee or extra money is demanded.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: deliveryLogo}}></div>
                <p>Reliable SMS Delivery</p>
              </div>
              <p>Our system is build upon dedicated servers that provide lighting fast sms delivery. Send SMS at any time and get it delivered within minute.</p>
            </div>
          </div>
        </div>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: dashboardLogo}}></div>
                <p>Dashboard</p>
              </div>
              <p>Dashboard is designed to provide graph representation of monthly usage, recent activities, SMS balances and many more.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: sendToDNDLogo}}></div>
                <p>Send to DND numbers</p>
              </div>
              <p>Frustrated on sending sms just to Non-DND numbers? Buy our amazing trasactional gateway which allows SMS to be sent on DND numbers too. No promotions please!</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: senderIdLogo}}></div>
                <p>Unique Sender Id</p>
              </div>
              <p>Have your own unique 6 character sender id to send SMS that will reflect as a sender name to the recipients like IX-EPDHAI</p>
            </div>
          </div>
        </div>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: smsScheduleLogo}}></div>
                <p>SMS Scheduling</p>
              </div>
              <p>Don't you get much time to access our panel and send your SMSs? Don't worry, just login once and order your messages to get themselves delivered at the time and date you want.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: undertakingLogo}}></div>
                <p>NDNC Undertaking</p>
              </div>
              <p>The TRAI rules are harsh, we need to follow them. But, we have made a little form for you to understand them well. This undertaking will make the TRAI confident on you!</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: unlimitedLogo}}></div>
                <p>SMS Validity</p>
              </div>
              <p>Hurray, We provide unlimited validity to it's users. So now your remaining sms stays in your account for years unless and until you use them.</p>
            </div>
          </div>
        </div>
      </div>
      <p className="promotional-features-table-tag">Transactional SMS Features</p>
      <table className="promotional-features-table">
        <tbody>
          <tr>
            <td className="dark">Delivery Time</td>
            <td>24x7 Delivery</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">DND Numbers</td>
            <td>Send to DND Numbers as well.</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Campaign option</td>
            <td>Data provided at extra cost.</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Set up cost</td>
            <td>Zero Charges</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Service & Support</td>
            <td>Free</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
        </tbody>
      </table>
    </div>
    <Footer />
  </div>
)

export default TransactionalSMS
