import React, {Component} from 'react';
import {LocalPostOffice} from '@mui/icons-material';
import {CircularProgress, IconButton, Popover, Tooltip} from '@mui/material';
import {connect} from 'react-redux';

import {getAllSMSHistory, getTrackCampaign} from '../../actions/smsHistory';
import Table from '../../components/Table';
import {GAEventTracker} from '../../lib/googleAnalytics';
import {dateFormat} from '../../lib/utils';

const langMap = {failed : 'Failed', done : 'Delivered', pending : 'Pending', DELIVRD : 'Delivered', INVALID : 'Invalid', visited: 'Visited'};

class AllSMSHistory extends Component {
  constructor(props){
    super(props);
    this.state = {
      smsList : [],
      showWait : false,
      anchorEl: null,
      showTrackData: false,
      smsText: ''
    }
    this.orgSMSList = [];
    this.receivedHistory = null;
    this.receiveShowMessage = true;
  }
  componentDidMount(){
    const startDate = new Date()
    startDate.setDate(startDate.getDate - 30)
    this.receivedHistory = false;
    this.props.getAllSMSHistory({});
  }
  componentWillReceiveProps(nextProps){
    let {smsHistoryList, trackCampaignRes} = nextProps;
    if(this.receivedHistory === false && this.state.smsList.length == 0 && smsHistoryList){
      if(smsHistoryList.success){
        let smsList = smsHistoryList.data.smsTransaction;
        this.receivedHistory = true;
        this.orgSMSList = smsList
        this.setState({smsList});
      } else if(smsHistoryList.err){
        alert(smsHistoryList.err.msg);
        this.receivedHistory = true;
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
    if (!this.receiveShowMessage && trackCampaignRes) {
      if (trackCampaignRes.success) {
        this.receiveShowMessage = true;
        this.setState({smsText: trackCampaignRes.data.smsText, showTrackData: true});
      } else if (trackCampaignRes.err) {
        alert(trackCampaignRes.err.msg);
        this.receiveShowMessage = true;
        this.setState({showTrackData: false, anchorEl: null});
      } else {
        alert("Something went wrong!");
        window.location.reload();
      }
    }
  }
  showMessage = (event, data) => {
    event.preventDefault();
    event.stopPropagation()
    this.receiveShowMessage = false;
    if (data.smsText) {
      this.setState({anchorEl: event.currentTarget, showTrackData: true, smsText: data.smsText});  
    } else {
      this.setState({anchorEl: event.currentTarget, showTrackData: false});
      this.props.getTrackCampaign(data.smsHistoryId, {smsText: 0});
    }
    GAEventTracker({category: 'SMS-History', action: 'Show Track Campaign'});
  }
  closeTrackCampaign = () => this.setState({anchorEl: null, showTrackData: false, smsText: ''});
  render() {
    let {smsList, showTrackData, anchorEl, smsText} = this.state;
    const isMobile = window.isMobile();
    return (
      <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
        {this.orgSMSList.length ? <div>
          {smsList.length ?
            <Table
              column={[
                {name: 'Service', value: 'smsType'},
                {name: 'Mobile/Sender', value: 'mobile', cell: (val, row) => (<>{val} <p style={{fontSize:10, marginTop: 3, color: '#3b3d3e'}}>{row.senderId}</p></>)},
                {name: 'Sent At', value: 'createdAt', cell : (val) => dateFormat(val)},
                {name: 'Updated At', value: 'statusUpdatedAt', cell: val => val ? dateFormat(val): ""},
                {name: 'Status', value: 'status', cell: val => langMap[val] || val},
                {name: 'Message', value: 'smsText', cell: (val, row) => (
                  <Tooltip title="View Message">
                    <IconButton aria-label='show' onClick={e => {this.showMessage(e, row)}}><LocalPostOffice /></IconButton>
                  </Tooltip>)}
              ]}
              data={smsList}
              showPagination
              serialNumber
              defaultPageSize={100}
            /> : <p style={{fontSize: 16}}>No Sent Messages found with applied filter, Please reset the filter!</p>
          }
        </div> :  <div>
          {this.receivedHistory ?
            <p style={{padding: 50, fontSize: 16, color: '#333'}}>Currently No Sent Messages Available, Please do send your first SMS!</p>
            :
            <CircularProgress size={80} thickness={5} />
          }
        </div>}
        {Boolean(anchorEl) && <Popover
          open
          anchorEl={anchorEl}
          anchorOrigin={{horizontal: 'left', vertical: 'top'}}
          transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
          onClose={this.closeTrackCampaign}
          disableScrollLock
        >
          {
            showTrackData ?
              <div style={{padding: '16px 24px', width: 300}}>
                <p style={{fontSize: 16, paddingBottom: 10, fontWeight: 500, marginBottom: 5, borderBottom: '1px solid #d4d4d3'}}>Message</p>
                <p style={{paddingTop: 10, fontSize: 14}}>{smsText}</p>
              </div>
              : <CircularProgress />
          }

        </Popover>
        }
      </div>
    )
  }
}
function mapStateToProps(state){
  return {
    smsHistoryList: state.smsHistory.getAllHistoryResponse,
    trackCampaignRes: state.smsHistory.getTrackCampaignResponse
  };
}
export default connect(mapStateToProps, {getAllSMSHistory, getTrackCampaign})(AllSMSHistory);
