import Api from '../api';
import { GET_TEMPLATE, GET_TEMPLATE_URL, DELETE_TEMPLATE, POST_TEMPLATE, EDIT_TEMPLATE } from '../lib/constant';
import { routeToLogin, getCookie } from '../lib/utils';

export function getTemplate() {
  const request = Api.get(GET_TEMPLATE_URL, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: GET_TEMPLATE, payload: data });
    }).catch((data) => {
      if (data.response.status === 404 && data.response.data.success === false) {
        routeToLogin();
      }
    });
  };
}

export function deleteTemplate(id) {
  const request = Api.delete(`${ GET_TEMPLATE_URL }/${ id }`, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: DELETE_TEMPLATE, payload: data });
    }).catch((data) => {
      if (data.response.status === 500 && data.response.data.success === false) {
        dispatch({ type: DELETE_TEMPLATE, payload: data.response.data });
      } else if (data.response.status === 404 && data.response.data.success === false) {
        routeToLogin();
      }
    });
  };
}

export function postTemplate(params) {
  const request = Api.post(GET_TEMPLATE_URL, params, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: POST_TEMPLATE, payload: data });
    }).catch((data) => {
      if (data.response.status === 500 && data.response.data.success === false) {
        dispatch({ type: POST_TEMPLATE, payload: data.response.data });
      } else if (data.response.status === 404 && data.response.data.success === false) {
        routeToLogin();
      }
    });
  };
}

export function editTemplate(id, params) {
  const request = Api.put(`${ GET_TEMPLATE_URL }/${ id }`, params, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: EDIT_TEMPLATE, payload: data });
    }).catch((data) => {
      if (data.response.status === 500 && data.response.data.success === false) {
        dispatch({ type: EDIT_TEMPLATE, payload: data.response.data });
      } else if (data.response.status === 404 && data.response.data.success === false) {
        routeToLogin();
      }
    });
  };
}
