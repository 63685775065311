import React, {useEffect, useState} from 'react'
import {Card, CardContent, Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {BarChart} from '@mui/x-charts/BarChart'
import {isEmpty} from 'lodash'
import {connect} from 'react-redux'

import {formatNumber} from '../../lib/utils'
import ErrorMsg from './ErrorMsg'
import Heading from './Heading'
import CONSTANT from './constant'
import {getSMSUsage} from './helper'

const SMSUsage = ({smsStats, dashboardData}) => {
  const [duration, setDuration] = useState('days')
  const [smsType, setSmsType] = useState([])
  const [senderID, setSenderID] = useState([])
  
  useEffect(() => {
    if (dashboardData?.data?.smsTypeTaken) {
      setSmsType(dashboardData?.data?.smsTypeTaken)
    }
    if (dashboardData?.data?.senderIds) {
      setSenderID(dashboardData?.data?.senderIds)
    }
  }, [dashboardData])

  if (isEmpty(smsStats) || isEmpty(dashboardData)) {
    return <Skeleton variant="rounded" width={'100%'} height={474} />
  }

  const handleChange = (event, newDuration) => setDuration(newDuration)

  const handleSMSTypeChange = (event) => setSmsType(event.target.value)

  const handleSenderIDChange = (event) => setSenderID(event.target.value)

  const dataset = getSMSUsage(smsStats?.data, duration, smsType, senderID)

  return (
    <Card>
      <CardContent>
        <Heading>SMS Usage</Heading>
        {dashboardData?.data?.smsTypeTaken && dashboardData?.data?.senderIds && smsStats?.data ?
          <>
            <div className='dashboard-sms-usage'>
              <div className='dashboard-sms-usage-selection'>
                {dashboardData.data.smsTypeTaken.length > 1 &&
                  <FormControl sx={{m: 1, minWidth: 150, maxWidth: 200}} size="small">
                    <InputLabel id="smsTypeLabel">SMS Type</InputLabel>
                    <Select
                      labelId="smsTypeLabel"
                      id="smsType"
                      value={smsType}
                      label="SMS Type"
                      onChange={handleSMSTypeChange}
                      input={<OutlinedInput label="SMS Type" />}
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {dashboardData.data.smsTypeTaken.map(el => (
                        <MenuItem key={el} value={el}>
                          <Checkbox checked={smsType.includes(el)} />
                          {CONSTANT.SMS_TYPE_MAP[el].name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                {dashboardData.data.senderIds.length > 1 &&
                  <FormControl sx={{m: 1, minWidth: 150, maxWidth: 200}} size="small">
                    <InputLabel id="senderIDsLabel">Headers</InputLabel>
                    <Select
                      labelId="senderIDsLabel"
                      id="senderIDs"
                      value={senderID}
                      label="Headers"
                      onChange={handleSenderIDChange}
                      input={<OutlinedInput label="Headers" />}
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {dashboardData.data.senderIds.map(el => (
                        <MenuItem key={el} value={el}>
                          <Checkbox checked={senderID.includes(el)} />
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              </div>
              <div className='dashboard-sms-usage-duration'>
                <ToggleButtonGroup
                  color="primary"
                  value={duration}
                  exclusive
                  onChange={handleChange}
                  aria-label="Duration"
                  size="small"
                >
                  <ToggleButton value="hours" disableFocusRipple disableRipple>Today</ToggleButton>
                  <ToggleButton value="days" disableFocusRipple disableRipple>Month</ToggleButton>
                  <ToggleButton value="months" disableFocusRipple disableRipple>Year</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            {dataset.length ?
              <BarChart
                height={350}
                dataset={dataset}
                series={CONSTANT.SMS_USAGE_TYPE}
                xAxis={[{dataKey: 'name', scaleType: 'band'}]}
                yAxis={[{valueFormatter: value => formatNumber(value)}]}
                colors={['#2196f3', '#4caf50']}
                axisHighlight={{x: 'line', y: 'none'}}
                slotProps={{
                  legend: {
                    position: {
                      vertical: 'bottom',
                      horizontal: 'bottom'
                    },
                    itemMarkWidth: 8,
                    itemMarkHeight: 8,
                    padding: -4
                  }
                }}
              /> :
              <ErrorMsg msg={`No sms history available for ${duration}`} />}
          </>
          :
          <div className='dashboard-card-container'>
            <ErrorMsg msg={dashboardData?.err?.msg || smsStats?.err?.msg} />
          </div>
        }
      </CardContent>
    </Card>
  )

}


function mapStateToProps(state){
  return{
    smsStats : state.dashboard.smsStats,
    dashboardData : state.dashboard.dashboard
  }
}

export default connect(mapStateToProps)(SMSUsage)