/* eslint-disable max-len */
import React from 'react';
import {Helmet} from "react-helmet";

import {checkedLogo, contactLogo, downloadLogo, freeLogo, groupLogo, manageLogo, uploadLogo} from './WebLogo';
import Footer from './footer';
import Header from './header';

const ContactManagementSystem = () =>(
  <div>
    <Helmet>
      <title>Contact Management System | Edumarc Technologies</title>
      <meta name="keywords" content="bulk sms service, bulk sms contact management, contact management, manage contact, uplod excel, excel upload sms" />
      <meta name="description" content="Contact Management System with contact and groups, send bulk sms service." />
    </Helmet>
    <Header headerClass="activeBlue" activeMenu='ContactManagement' />
    <div className="promotiona-section container">
      <div className="svg-icon" dangerouslySetInnerHTML={{__html: contactLogo}}></div>
      <h1>Manage your contacts and groups efficiently</h1>
      <h2>Best Contact Management Tools in Industry to manage your contacts and send bulk sms to contacts and groups easily.</h2>
      <div className="promotional-features">
        <p>Benefist you will get with our system</p>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: freeLogo}}></div>
                <p>Free, with No Cost</p>
              </div>
              <p>Guarantees lowest price for the bestest services we provide. High services = low price is the key-feature. Find a low price & we will match it.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: manageLogo}}></div>
                <p>Manage Contacts</p>
              </div>
              <p>Add, modify, delete your contacts. Save and manage your contacts as per your requirement at one place.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: groupLogo}}></div>
                <p>Create & Manage Groups</p>
              </div>
              <p>Create groups and manage your contacts based upon groups, modify existing groups, add contacts to multiple groups.</p>
            </div>
          </div>
        </div>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                <p>Save Contacts</p>
              </div>
              <p>Save all your contacts free of cost and use as per your requirements. You can send SMS to saved contacts without typings numbers again & again.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: uploadLogo}}></div>
                <p>Upload Bulk Contacts</p>
              </div>
              <p>Tired of saving each contacts individually, upload your excel sheets and save them in single click, you don't have names, no worries we will creat name for those numbers.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: downloadLogo}}></div>
                <p>Download Contacts</p>
              </div>
              <p>Download all your saved contacts in excel sheet, download groupwise contacts as well.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)
   

export default ContactManagementSystem
