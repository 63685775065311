/* eslint-disable max-len */
import React from 'react';
import {FormatQuote} from '@mui/icons-material';
import {Link} from 'react-router-dom';

import {
  OTPLogo,
  businessLogo,
  dashboardLogo,
  deliveryLogo,
  entertainmentLogo,
  freeLogo,
  hospitalLogo,
  hotelLogo,
  manageLogo,
  promoSMSLogo,
  regionalLangLogo,
  responsiveLogo,
  schoolLogo,
  smsAPILogo,
  smsScheduleLogo,
  smsTrackLogo,
  startUpLogo,
  transSMSLogo,
  unlimitedLogo
} from './WebLogo';
import Footer from './footer';
import Header from './header';
import RequestDemo from './requestDemo';

const Website = () => {
  const isMobile = window.isMobile();
  return (
    <div>
      <Header headerClass="ed-white" />
      <div className="globalContent">
        <main>
          <header>
            <section id="intro">
              <div className="container-lg">
                <h1>Premium Messaging Platform For Your Business</h1>
                <h2>Connect with your customers through our high delivery SMS and user friendly API services at affordable costs.</h2>
                {
                  isMobile ?
                    null
                    :
                    <section id="illustrations">
                      <div className="phone-small">
                        <img src="https://d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/SiteBGFinal.png" className="u_posR" alt="Bulk SMS Service provider" />
                      </div>
                    </section>
                }
              </div>
            </section>
            <section className="globalFooterCard sms-toolkit">
              <RequestDemo />
            </section>
          </header>
          <section className="trusted-brand">
            <div className='container-brand'>
              <div className='brand-heading'>
                <div className='line'><h2>Trusted By 300+ Brands</h2></div>
              </div>
              <div className='brand-heading'>
                <div className='line clientSec'>
                  <div className='logos clientSecSlider'>
                    <div className="logos-item">
                      <img src="https://s3.ap-south-1.amazonaws.com/client-ui/Website/Images/ClientLogo/KoshexLogo.svg" className="logos-image" width="150px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://s3.ap-south-1.amazonaws.com/client-ui/Website/Images/ClientLogo/EdugorillaLogo.png" className="logos-image" width="210px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://s3.ap-south-1.amazonaws.com/client-ui/Website/Images/ClientLogo/MaitriBodh.png" className="logos-image" width="100px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://client-ui.s3.ap-south-1.amazonaws.com/Website/Images/ClientLogo/MGL.png" className="logos-image" width="60px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://s3.ap-south-1.amazonaws.com/client-ui/Website/Images/ClientLogo/IIFLLogo.png" className="logos-image" width="150px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://client-ui.s3.ap-south-1.amazonaws.com/Website/Images/ClientLogo/spiderG-logo-1.png" className="logos-image" width="140px" />
                    </div>
                  </div>
                  <div className='logos clientSecSlider extraClientSecSlider'>
                    <div className="logos-item">
                      <img src="https://s3.ap-south-1.amazonaws.com/client-ui/Website/Images/ClientLogo/KoshexLogo.svg" className="logos-image" width="150px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://s3.ap-south-1.amazonaws.com/client-ui/Website/Images/ClientLogo/EdugorillaLogo.png" className="logos-image" width="210px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://s3.ap-south-1.amazonaws.com/client-ui/Website/Images/ClientLogo/MaitriBodh.png" className="logos-image" width="100px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://client-ui.s3.ap-south-1.amazonaws.com/Website/Images/ClientLogo/MGL.png" className="logos-image" width="60px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://s3.ap-south-1.amazonaws.com/client-ui/Website/Images/ClientLogo/IIFLLogo.png" className="logos-image" width="150px" />
                    </div>
                    <div className="logos-item">
                      <img src="https://client-ui.s3.ap-south-1.amazonaws.com/Website/Images/ClientLogo/spiderG-logo-1.png" className="logos-image" width="140px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
                            
          </section>
        </main>
      </div>
      <div className="globalContent">
        <section className="container-lg card-details-features" style={{marginBottom: 30}}>
          <p className="features-home">Exclusive Features</p>
          <div className="promotional-features-section">
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: manageLogo}}></div>
                  <p>Contact/Group Management System</p>
                </div>
                <p>Store Contacts and Numbers which can be used to send SMS easily. Create and Manage Groups with saved contacts. You can manage your contacts and groups as per your requirements.</p>
              </div>
            </div>
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: deliveryLogo}}></div>
                  <p>Instant-Reliable Delivery</p>
                </div>
                <p>We provide instant delivery of SMS without any delay with maximum delivery rate. Just send and sit back without any sms delivery concern.</p>
              </div>
            </div>
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: smsTrackLogo}}></div>
                  <p>Track Campaign & Live Report</p>
                </div>
                <p>Take the guesswork out of your SMS marketing campaigns! Get complete report of your campaigns with status corresponding to each and every number.</p>
              </div>
            </div>
          </div>
          <div className="promotional-features-section">
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: responsiveLogo}}></div>
                  <p>Mobile-Web Friendly & Easy to Use</p>
                </div>
                <p>Send Bulk SMS text messages from right within your web browser with all devices. No download. Nothing to install. Available anywhere, anytime.</p>
              </div>
            </div>
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: freeLogo}}></div>
                  <p>Free Setup, Service & Support</p>
                </div>
                <p>Complete setup without any extra cost, you can connect with us at any time, we will never ask for any extra charges. No hidden cost policy.</p>
              </div>
            </div>
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: smsScheduleLogo}}></div>
                  <p>Scheduling Messages</p>
                </div>
                <p>Don't you get much time to access our panel? Don't worry, just login once and order your messages to get themselves delivered at the time and date you want.</p>
              </div>
            </div>
          </div>
          <div className="promotional-features-section">
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: dashboardLogo}}></div>
                  <p>Dashboard</p>
                </div>
                <p>Live Dashboard to give detailed view of usage with graphs and complete statistics of your messages. Last activity track, monthly usage, available balance all at one place.</p>
              </div>
            </div>
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: regionalLangLogo}}></div>
                  <p>Regional Language Support</p>
                </div>
                <p>Send SMS in Indian language, Communicate to your audience by messaging them just in the language they are comfortable with.</p>
              </div>
            </div>
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: unlimitedLogo}}></div>
                  <p>Unlimited Validity</p>
                </div>
                <p>Buy once, use for lifetime policy. Your sms balance will not expire, send at your ease. Buy in bulk at cheaper prices and use for lifetime.</p>
              </div>
            </div>
          </div>
          <div className="separator">
            <hr />
            <hr className="hr1" />
            <hr className="hr2" />
            <hr className="hr1" />
            <hr />
          </div>
          <p className="features-home">Bulk SMS Services</p>
          <div className="promotional-features-section services-provided">
            <div className="list">
              <Link className="box-list" to="/promotional-bulk-sms">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: promoSMSLogo}}></div>
                  <p>Promotional SMS</p>
                </div>
                <p>Promotional SMS is generally used for sending any offers or promotions to new and existing customers. Messages are sent to non-DND numbers and opt-in numbers between 9am and 9pm only from your own 6-character Sender ID. Start your free trial today!</p>
              </Link>
            </div>
            <div className="list">
              <Link className="box-list" to="/transactional-bulk-sms">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: transSMSLogo}}></div>
                  <p>Transactional SMS</p>
                </div>
                <p>Transactional route can only be used for sending transactional SMS such as OTPs and alerts to your registered users. Messages can be sent 24/7 from your own 6-character Sender ID. To configure a transactional route, create your account today. No setup costs involved!</p>
              </Link>
            </div>
            <div className="list">
              <Link className="box-list" to="/transactional-bulk-sms">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: OTPLogo}}></div>
                  <p>OTP SMS</p>
                </div>
                <p>Send One Time Password (OTP) message with 100% success rate. Integrate our API to send OTP from your system, in case there is some issue at your end, you can send OTP SMS from our portal at any time without any delay in your customer services.</p>
              </Link>
            </div>
            <div className="list">
              <Link className="box-list" to="/transactional-bulk-sms">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: smsAPILogo}}></div>
                  <p>API Integration</p>
                </div>
                <p>Integrate Promotional, Transactional and OTP bulk SMS API's into your system. No need to access our system everytime to send SMS, you can send SMS from your system by integrating our API and send automated SMS.</p>
              </Link>
            </div>
          </div>
        </section>
        {
          isMobile ?
            <section className="customer-type m-customer-type">
              <div className="separator">
                <hr />
                <hr className="hr1" />
                <hr className="hr2" />
                <hr className="hr1" />
                <hr />
              </div>
              <p className="features-home">Business Use-cases</p>
              <div className="container-lg">
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: schoolLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Education</h2>
                  <p className="common-BodyText">Connect with parents instantly and convey the useful info!</p>
                </Link>
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: startUpLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Startup</h2>
                  <p className="common-BodyText">Integrate SMS with Powerful API and OTP Services!</p>
                </Link>
              </div>
              <div className="container-lg">
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: hospitalLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">HealthCare</h2>
                  <p className="common-BodyText">Send appointment updates, medical reports, patient bills!</p>
                </Link>
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: businessLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Business</h2>
                  <p className="common-BodyText">Grow Business with high delivery affordable SMS Campaigns!</p>
                </Link>
              </div>
              <div className="container-lg">
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: entertainmentLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Event Management</h2>
                  <p className="common-BodyText">Promote an event, collect RSVPs, send tickets, feedbacks!</p>
                </Link>
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: hotelLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Restaurants & Cafes</h2>
                  <p className="common-BodyText">Manage orders, send offers and marketing campaign SMS!</p>
                </Link>
              </div>
            </section>
            :
            <section className="customer-type d-customer-type">
              <div className="separator">
                <hr />
                <hr className="hr1" />
                <hr className="hr2" />
                <hr className="hr1" />
                <hr />
              </div>
              <p className="features-home">Business Use-cases</p>
              <div className="container-lg">

                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: startUpLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Startup</h2>
                  <p className="common-BodyText">Integrate SMS with Powerful API and OTP Services!</p>
                </Link>
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: businessLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Business</h2>
                  <p className="common-BodyText">Grow Business with high delivery affordable SMS Campaigns!</p>
                </Link>
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: hotelLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Restaurants & Cafes</h2>
                  <p className="common-BodyText">Manage orders, send offers and marketing campaigns SMS!</p>
                </Link>
              </div>
              <div className="container-lg">
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: schoolLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Education</h2>
                  <p className="common-BodyText">Connect with parents instantly and convey the useful info!</p>
                </Link>
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: entertainmentLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">Event Management</h2>
                  <p className="common-BodyText">Promote an event, collect RSVPs, send tickets, feedbacks!</p>
                </Link>
                <Link className="card-documentation card-college" to="/bulk-sms-services">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: hospitalLogo}}></div>
                  <h2 className="commonUppercase common-Link--arrow">HealthCare</h2>
                  <p className="common-BodyText">Send appointment updates, medical reports, patient bills!</p>
                </Link>

              </div>
            </section>
        }
      </div>
      <div className="globalContent">
        <section className="clients">
          <p className="clientHeader"><span> Testimonials</span></p>
          <div className="clientSpeak">
            <div>
              <FormatQuote />
              <h2>Sandeep Kumar</h2>
              <h4>Co- Founder at TrueCover Consultants Pvt.Ltd</h4>
              <p>Thanks Edumarc Technologies, I am enjoying the platform, its so easy to use. I shall surely recommend you guys to my other business colleagues.</p>
            </div>
            <div>
              <FormatQuote />
              <h2>Vijesh Hegde</h2>
              <h4>Karnatka Agriculture Department</h4>
              <p>You guys have done a brilliant work. Happy with your neat and honest service.</p>
            </div>
            <div>
              <FormatQuote />
              <h2>Raja Lambert</h2>
              <h4>Manager,Grace Modern Jr. H. School</h4>
              <p>
                    The best thing is that the follow-ups and suggestions are converted into applications accordingly. Very cooperative, responsive and responsible team of IT professionals. Our best wishes to the whole edumarcsms.com Team.
              </p>
            </div>

          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Website
