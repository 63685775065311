import Api from '../api';
import {
    GET_SMS_HISTORY, GET_SMS_HISTORY_URL,SMS_HISTORY_STATUS_URL,SMS_HISTORY_SMART_STATUS, SMS_HISTORY_SMART_STATUS_URL, SMS_HISTORY_STATUS, SMS_HISTORY_DWNL_REPORT,
    SMS_HISTORY_DWNL_REPORT_URL, SMS_HISTORY_DWNL_FILTER_REPORT,SMS_HISTORY_DWNL_FILTER_REPORT_URL,GET_TRACK_CAMPAIGN, GET_ALL_SMS_HISTORY_URL, GET_ALL_SMS_HISTORY} from '../lib/constant';
import { routeToLogin, getCookie } from '../lib/utils';

export function getSMSHistory(params){
    const request = Api.post(GET_SMS_HISTORY_URL, params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:GET_SMS_HISTORY,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function getAllSMSHistory(params){
    const request = Api.post(GET_ALL_SMS_HISTORY_URL, params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:GET_ALL_SMS_HISTORY,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function getStatusSMSHistory(id){
    const request = Api.get(SMS_HISTORY_STATUS_URL.replace('tId',id),'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:SMS_HISTORY_STATUS,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function getSmartStatusSMSHistory(id){
    const request = Api.get(SMS_HISTORY_SMART_STATUS_URL.replace('tId',id),'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:SMS_HISTORY_SMART_STATUS,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function getTrackCampaign(id, params){
    let url = GET_SMS_HISTORY_URL+'/'+id;
    const request = Api.get(url, 'bearer ' + getCookie('userToken'), params);
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:GET_TRACK_CAMPAIGN,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function getSMSReport(id, isSmartURL = 0){
    const request = Api.get(SMS_HISTORY_DWNL_REPORT_URL.replace('tId',id),'bearer '+getCookie('userToken'),{isSmartURL});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:SMS_HISTORY_DWNL_REPORT,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}


export function getSMSFilterReport(sDate,eDate){
    const request = Api.get(SMS_HISTORY_DWNL_FILTER_REPORT_URL.replace('sDate',sDate).replace('eDate',eDate),'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:SMS_HISTORY_DWNL_FILTER_REPORT,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
