import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import React, {Component} from 'react';
import Select from '../../components/Select';

export default class ChangeStatus extends Component {
  constructor(props){
    super(props);
    this.state={
      val : props.status
    }
  }
  handleChange = (e) => this.setState({val: e.target.value});
  render(){
    const {val} = this.state;
    return(
      <Dialog
        open={true}
        onClose={this.props.closeChangeStatus}
      >
        <DialogTitle>Change Status</DialogTitle>
        <DialogContent style={{paddingTop: 16}}>
          <Select
            label="Select Account Type"
            value={val}
            fullWidth
            required
            onChange={this.handleChange}
            options={[{value: "testing", name: "Testing"}, {value: "live", name: 'Live'}]}
            sx={{minWidth: 150}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeChangeStatus}>
            Cancel
          </Button>
          <Button variant='contained' onClick={e => this.props.changeStatus({accountStatus : val})}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
