/* eslint-disable max-len */
import React from 'react';
import {Helmet} from "react-helmet";

import {
  bulkSMSLogo,
  checkedLogo,
  dashboardLogo, deliveryLogo,
  dndFilterLogo,
  lowPriceLogo,
  promoSMSLogo,
  senderIdLogo,
  smsScheduleLogo, supportLogo,
  unlimitedLogo
} from './WebLogo';
import Footer from './footer';
import Header from './header';


const PromotionalSMS = () => (
  <div>
    <Helmet>
      <title>Promotional Bulk SMS | Edumarc</title>
      <meta
        name="keywords"
        content="send promotional sms, send promotional bulk sms, bulk sms marketing, business bulk sms, sms bulk service, free sms, bulk sms service, send sms from excel sheet, upload excel sheet to send sms"
      />
      <meta name="description" content="how do i send Promotional, Transactional, Marketing and Business Bulk SMS online" />
    </Helmet>
    <Header headerClass="activeBlue" activeMenu='PromotionalSMS' />
    <div className="promotiona-section container">
      <div className="svg-icon" dangerouslySetInnerHTML={{__html: promoSMSLogo}}></div>
      <h1>Promote your brand with Promotional Bulk SMS</h1>
      <h2>Best sms marketing tool in Industry to target bulk audience with fast, reliable and deliverable tool.</h2>
      <div className="promotional-features">
        <p>Benefist you will get with our system</p>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: lowPriceLogo}}></div>
                <p>Low Price</p>
              </div>
              <p>Guarantees lowest price for the bestest services we provide. High services = low price is the key-feature. Find a low price & we will match it.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: supportLogo}}></div>
                <p>Free Setup, Service & Support</p>
              </div>
              <p>We are very lenient :), so we just charge for the SMSs you buy and nothing extra. No setup fee or extra money is demanded.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: deliveryLogo}}></div>
                <p>Reliable SMS Delivery</p>
              </div>
              <p>Our system is build upon dedicated servers that provide lighting fast sms delivery. Delivery time – 9 am to 9 pm.</p>
            </div>
          </div>
        </div>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: dashboardLogo}}></div>
                <p>Dashboard</p>
              </div>
              <p>Dashboard is designed to provide graph representation of monthly usage, recent activities, SMS balances and many more.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: dndFilterLogo}}></div>
                <p>DND Filters</p>
              </div>
              <p>Do not worry about the DND numbers in your contact list. Our DND Filterers will pull out the rushing numbers from the list and will send them back to you.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: senderIdLogo}}></div>
                <p>Unique Sender Id</p>
              </div>
              <p>Have your own unique 6 character sender id to send SMS that will reflect as a sender name to the recipients like IX-EPDHAI</p>
            </div>
          </div>
        </div>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: smsScheduleLogo}}></div>
                <p>SMS Scheduling</p>
              </div>
              <p>Don't you get much time to access our panel and send your SMSs? Don't worry, just login once and order your messages to get themselves delivered at the time and date you want.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: bulkSMSLogo}}></div>
                <p>Bulk SMS</p>
              </div>
              <p>Make a BOOM for your business in your working area using our promotional bulk sms gateway. Use it for marketing, promotions, advertisement and more.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: unlimitedLogo}}></div>
                <p>SMS Validity</p>
              </div>
              <p>Hurray, We provide unlimited validity to it's users. So now your remaining sms stays in your account for years unless and until you use them.</p>
            </div>
          </div>
        </div>
      </div>
      <p className="promotional-features-table-tag">Promotional SMS Features</p>
      <table className="promotional-features-table">
        <tbody>
          <tr>
            <td className="dark">Delivery Time</td>
            <td>10:00 AM to 9:00 PM</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">DND Numbers</td>
            <td>Automatically filtered</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Campaign option</td>
            <td>Data provided at extra cost.</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Set up cost</td>
            <td>Zero Charges</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Service & Support</td>
            <td>Free</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
        </tbody>
      </table>
    </div>
    <Footer />
  </div>
)

export default PromotionalSMS
