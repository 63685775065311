import React, {Component} from 'react';
import {Add, Delete as DeleteIcon, Edit} from '@mui/icons-material';
import {Button, CircularProgress, IconButton, Tooltip} from '@mui/material';
import {connect} from 'react-redux';

import {getTemplate} from '../../actions/template';
import Table from '../../components/Table';
import {dateFormat} from '../../lib/utils';
import Delete from './Delete';
import TemplateForm from './TemplateForm';

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateList: [],
      selectedTemplate: null,
      showAdd: false,
      showDelete: false
    };
    this.receiveTemplate = false;
  }
  componentDidMount() {
    this.props.getTemplate();
  }
  componentWillReceiveProps(nextProps) {
    const {templateList} = nextProps;
    if (this.receiveTemplate === false && this.state.templateList.length === 0 && templateList) {
      if (templateList.success) {
        this.receiveTemplate = true;
        this.setState({templateList: templateList.data});
      } else if (templateList.err) {
        alert(templateList.err.msg);
        this.receiveTemplate = true;
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
  }
  handleClose = () => {
    this.setState({selectedTemplate: null, showDelete: false});
  }
  handleSuccess = () => {
    this.receiveTemplate = false;
    this.props.getTemplate();
    this.setState({selectedTemplate: null, templateList: [], showAdd: false, showDelete: false});
  }
  toggleAdd = (e) => {
    e.stopPropagation()
    this.setState(prevState => ({showAdd: !prevState.showAdd, selectedTemplate: null}))
  }
  deleteTemplate = (e, data) => {
    e.stopPropagation()
    this.setState({selectedTemplate: data, showDelete: true})
  }
  editTemplate = (e, data) => {
    e.stopPropagation()
    this.setState({selectedTemplate: data, showAdd: true})
  }

  render() {
    const {templateList, selectedTemplate, showAdd, showDelete} = this.state;
    const isMobile = window.isMobile();
    return (
      <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={{fontSize: '1rem'}}>{templateList.length > 0 ? templateList.length : '' } Templates</p>
          <Button onClick={this.toggleAdd} startIcon={<Add />} variant='contained' style={{marginLeft: 'auto'}}>Add Template</Button>
        </div>
        {
          templateList.length ?
            <div style={ {paddingTop: 16} }>
              <Table
                column={[
                  {name: 'Header', value: 'senderId', style:{width: '10%'}}, {name: 'Template Name', value: 'name', style:{minWidth: 135}},
                  {name: 'Template', value: 'text', style:{width: '20%', whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: 1.3}},
                  {name: 'Template ID', value: 'id', style:{width: '15%'}}, {name: 'DTM', value: 'updatedAt', style:{width: '18%'}, cell: val => dateFormat(val)},
                  {name: 'Status', value: 'status', style:{width: '7%'}},
                  {name: 'Action', style:{width: '15%'}, value: '', cell:  (id, original) => (<><Tooltip title="Edit Template">
                    <IconButton aria-label="Edit" onClick={e => this.editTemplate(e, original)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Template">
                    <IconButton aria-label="Delete" onClick={e => this.deleteTemplate(e, original)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip></>)}
                ]}
                data={templateList}
              />
            </div>
            :
            <div>
              {
                this.receiveTemplate ?
                  <div>
                    <p style={ {padding: 50, fontSize: 16, color: '#333', paddingBottom: 20} }>Currently No Template is added, Please add templates!</p>
                  </div>

                  :
                  <CircularProgress size={ 80 } thickness={ 5 } />
              }
            </div>
        }
        {
          showDelete &&
          <Delete
            handleSuccess={ this.handleSuccess }
            data={ selectedTemplate }
            onClose={ this.handleClose }
          />
        }
        {showAdd &&
          <TemplateForm
            handlePostTemplate={ this.handleSuccess }
            closeHandler={ this.toggleAdd }
            data={ selectedTemplate }
          />
        }
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    templateList: state.template.templateList
  };
}

export default connect(mapStateToProps, {getTemplate})(Template);
