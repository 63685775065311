/* eslint-disable max-len */
import React from 'react';
import {Helmet} from "react-helmet";

import {
  OTPLogo,
  checkedLogo,
  deliveryLogo,
  dndFilterLogo,
  lowPriceLogo,
  senderIdLogo,
  smsAPILogo,
  supportLogo
} from './WebLogo';
import Footer from './footer';
import Header from './header';


const OTPSMS = () => (
  <div>
    <Helmet>
      <title>OTP Bulk SMS Integration | Edumarc</title>
      <meta
        name="keywords"
        content="send otp sms, send sms otp, send one time password sms, send otp message, send one time password message, otp sms services, otp message services, otp sms Integration, otp message integration, otp integration"
      />
      <meta name="description" content="Integrate otp sms services, send otp sms messages from your system" />
    </Helmet>
    <Header headerClass="activeBlue" activeMenu='OTPSMS' />
    <div className="promotiona-section container">
      <div className="svg-icon" dangerouslySetInnerHTML={{__html: OTPLogo}}></div>
      <h1>Send OTP messages to your customer</h1>
      <h2>Best OTP sms facility provider in Industry to send OTP to your customer with fast, reliable and deliverable tool.</h2>
      <div className="promotional-features">
        <p>Benefist you will get with our system</p>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: lowPriceLogo}}></div>
                <p>Low Price</p>
              </div>
              <p>Guarantees lowest price for the bestest services we provide. High services = low price is the key-feature. Find a low price & we will match it.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: supportLogo}}></div>
                <p>Free Setup, Service & Support</p>
              </div>
              <p>We are very lenient :), so we just charge for the SMSs you buy and nothing extra. No setup fee or extra money is demanded.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: deliveryLogo}}></div>
                <p>Reliable SMS Delivery</p>
              </div>
              <p>Our system is build upon dedicated servers that provide lighting fast sms delivery. Send SMS at any time and get it delivered within minute.</p>
            </div>
          </div>
        </div>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: smsAPILogo}}></div>
                <p>API Integration</p>
              </div>
              <p>Integrate OTP SMS API and send OTP SMS from your system based upon and your logics and verify. It takes only minutes to integrate this super cool API.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: dndFilterLogo}}></div>
                <p>Send to All</p>
              </div>
              <p>Do not worry about the DND numbers. OTP messages will be delivered to all numbers at any time without any delay.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: senderIdLogo}}></div>
                <p>Unique Sender Id</p>
              </div>
              <p>Promotional gateway dynamically creates numeric sender ids that will reflect as a sender name to the recipients like LM-034121</p>
            </div>
          </div>
        </div>
      </div>
      <p className="promotional-features-table-tag">OTP SMS Features</p>
      <table className="promotional-features-table">
        <tbody>
          <tr>
            <td className="dark">Delivery Time</td>
            <td>24x7</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">DND Numbers</td>
            <td>Allowed</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Integration</td>
            <td>API will be provided free of cost.</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Set up cost</td>
            <td>Zero Charges</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
          <tr>
            <td className="dark">Service & Support</td>
            <td>Free</td>
            <td><div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div></td>
          </tr>
        </tbody>
      </table>
    </div>
    <Footer />
  </div>
)

export default OTPSMS
