import React from 'react';
import {Helmet} from "react-helmet";

import {checkedLogo} from './WebLogo';
import Footer from './footer';
import Header from './header';

const Features= () => {
  const isMobile = window.isMobile();
  return(
    <div>
      <Helmet>
        <title>Bulk SMS Features | edumarcsms</title>
        <meta           
          name="keywords"
          content="bulk sms service, bulk sms dashboard, upload excel to send sms, copy paste number to send sms, sms analytics, schedule sms, send sms anytime."
        />
        <meta name="description" content="Bulk sms Features, Dashboard, SMS Tracking, SMS Analytics, Schedule SMS Online bulk sms service." />
      </Helmet>
      <Header headerClass="activeBlue" activeMenu="Features" />
      <div className="promotiona-section features-page container">
        <h1>Handful of tools to enhance your SMS Marketing and Communication</h1>
        <h2>Best sms marketing tool in Industry to target bulk audience with fast, reliable and deliverable tool with as many features as required.</h2>
        {
          isMobile ?
            <div className="m-feature-details feature-details">
              <div className="col-2">
                <p>Send SMS with User Friendly Interface with minimum clicks</p>
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/mainportal.jpg" />
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Send SMS to All in one click</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Choose specific contacts/groups to send SMS</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Send SMS by uploading excel sheet</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Add contact numbers Manually</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Schedule SMS for later. It will be send automatically.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Use pre-saved templates and signatures, no need to write SMS.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Save Templates and Signatures.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Real time track of number of messages and text counts.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Send Promotional and Transactional SMS from single platform.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Choose multiple SenderId.</div>
                </div>
              </div>
              <div className="col-2">
                <p>Track your sent SMS with complete Reports</p>
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/smshistory.jpg" />
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Track all sent sms at one place.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Download each campaigns Reports.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Check delivery report status against each number.</div>
                </div>
              </div>
              <div className="col-2">
                <p>Contact Management System</p>
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/contactsui.jpg" />
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Add contact for later use.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Manage contacts as per your ease.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Upload bulk of numbers to access and send sms.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Download saved contacts for your use.</div>
                </div>
              </div>
              <div className="col-2">
                <p>Manage Groups and Contacts</p>
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/groupui.jpg" />
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Create groups with saved contacts.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Manage multiple groups based upon your requirements.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Download group contacts for your use.</div>
                </div>
              </div>
              <div className="col-2">
                <p>Dashboard</p>
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/dashboard.jpg" />
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">View Account Details.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Track Monthly Usage via Graph.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Track daily usage.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Check sms Balance.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Look for offers and deals.</div>
                </div>
              </div>
            </div>
            :
            <div className="feature-details">
              <div className="col-2">
                <p>Send SMS with User Friendly Interface with minimum clicks</p>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Send SMS to All in one click</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Choose specific contacts/groups to send SMS</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Send SMS by uploading excel sheet</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Add contact numbers Manually</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Schedule SMS for later. It will be send automatically.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Use pre-saved templates and signatures, no need to write SMS.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Save Templates and Signatures.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Real time track of number of messages and text counts.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Send Promotional and Transactional SMS from single platform.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Choose multiple SenderId.</div>
                </div>
              </div>
              <div className="col-2">
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/mainportal.jpg" alt="Send Bulk SMS" />
                <p className="imgText">Send SMS</p>
              </div>
              <div className="col-2">
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/smshistory.jpg" alt="Track SMS Campaign" />
                <p className="imgText">SMS History</p>
              </div>
              <div className="col-2" style={{paddingLeft: 20}}>
                <p>Track your sent SMS with complete Reports</p>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Track all sent sms at one place.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Download each campaigns Reports.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Check delivery report status against each number.</div>
                </div>
              </div>
              <div className="col-2">
                <p>Contact Management System</p>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Add contact for later use.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Manage contacts as per your ease.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Upload bulk of numbers to access and send sms.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Download saved contacts for your use.</div>
                </div>
              </div>
              <div className="col-2">
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/contactsui.jpg" alt="Contact Management System" />
                <p className="imgText">Contact Management</p>
              </div>
              <div className="col-2">
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/groupui.jpg" alt="Group Management System" />
                <p className="imgText">Group Management</p>
              </div>
              <div className="col-2" style={{paddingLeft: 20}}>
                <p>Manage Groups and Contacts</p>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Create groups with saved contacts.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Manage multiple groups based upon your requirements.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Download group contacts for your use.</div>
                </div>
              </div>
              <div className="col-2">
                <p>Dashboard</p>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">View Account Details.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Track Monthly Usage via Graph.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Track daily usage.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Check sms Balance.</div>
                </div>
                <div>
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: checkedLogo}}></div>
                  <div className="text">Look for offers and deals.</div>
                </div>
              </div>
              <div className="col-2">
                <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/dashboard.jpg" alt="Dashboard" />
                <p className="imgText">Dashboard</p>
              </div>
            </div>
        }
        <h3>Mobile Friendly, you can access all services and features via Mobile and Tablet</h3>
        <div className="feature-details-mobile">
          <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/MobileMainUI.jpeg" alt="Send Bulk SMS from Mobile" />
          <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/MobileHistory.jpg" alt="Track Campaign from Mobile" />
          <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/MobileDashboard.jpg" alt="Dashboard Mobile Friendly" />
          <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/MobileContactsUI.jpg" alt="Contact Management Mobile" />
          <img src="//d1eisuc5webqir.cloudfront.net/client-ui/Website/Images/MobileGroupUI.jpg" alt="Group Management Mobile" />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Features
